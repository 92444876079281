import { TProcuration, EProcurationStatus } from '@/interfaces/main'

export const compareDates = (d1: Date, d2: Date) => {
  const difference = d1.getTime() - d2.getTime()
  const totalDays = Math.ceil(difference / (1000 * 3600 * 24))
  return totalDays
}

export const normilizeProcuration = (p: TProcuration) => {
  const remainDays = compareDates(new Date(p.procuration_exp_date), new Date())
  let status = p.status
  if (remainDays < 31 && remainDays > 0 && p.status === EProcurationStatus.active) {
    status = EProcurationStatus.expiring
  }
  return {
    ...p,
    status: status
  }
}
