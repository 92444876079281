<script lang="ts" setup>
import Loader from '@/components/Loader/Loader.vue'
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import MainPageHeader from '@/components/MainPageHeader/MainPageHeader.vue'
import { UTabs, UButton } from 'unit-uikit'
import { useRoute } from 'vue-router'
import ErrorModal from '@/components/Modals/ErrorModal.vue'

const store = useStore()
const failedcreate = ref(false)
const processing = ref(false)
const route = useRoute()
const title = ref('')

const id = route.params.id as string

const tabs = [
  {
    routeName: 'workers.worker.info',
    routeParams: { id },
    label: 'Инфо'
  },
  {
    routeName: 'worker.history',
    routeParams: { id },
    label: 'История выплат'
  }
]

const getFullName = (worker: any) => {
  return `${worker.last_name || ''} ${worker.first_name || ''} ${worker.middle_name || ''}`
}

store
  .dispatch('worker/getSingleWorker', id)
  .then((res: any) => {
    title.value = getFullName(res)
  })
  .catch((e) => {
    console.error(e)
  })

const isPaymentHistoryPage = computed(() => {
  const name = route?.name
  return name === 'worker.history'
})

const createReport = () => {
  if (processing.value) {
    return
  }
  processing.value = true

  store
    .dispatch('worker/workerFinancialReport', { id: id, data: { status: 'paid' } })
    .then((res: any) => {
      // update first 10 documents
      const { file } = res
      if (file) {
        const URL_CLEAR = process.env.VUE_APP_URL
        const link = file
        const a = document.createElement('a')
        a.href = URL_CLEAR + '/' + link
        a.setAttribute('target', '_blank')
        a.setAttribute('download', 'report.xlsx')
        a.click()
      }
      processing.value = false
    })
    .catch((e: any) => {
      console.error(e)
      processing.value = false
      failedcreate.value = true
    })
}
</script>

<template>
  <Loader v-if="processing" />
  <main-page-header :title="title" class="page__title">
    <u-button v-if="isPaymentHistoryPage" label="Скачать отчёт" size="sm" @click="createReport" />
  </main-page-header>
  <u-tabs :tabs="tabs" />
  <router-view></router-view>

  <ErrorModal
    :show="failedcreate"
    errorMessage="Не удалось сформировать отчёт"
    :errorAction="
      () => {
        failedcreate = false
      }
    "
  />
</template>
