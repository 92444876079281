import { createPinia } from 'pinia'
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios, { InternalAxiosRequestConfig, AxiosRequestHeaders } from 'axios'
import { useAuthStore } from '@/stores/auth'
import { vTooltip, vMask } from 'unit-uikit'

//CSS IMPORTS
import 'unit-uikit/dist/style.css'
import './assets/styles/style.css'
import './assets/styles/main.postcss'
import './assets/styles/typography.postcss'
import './assets/styles/page-tabs.postcss'

const pinia = createPinia()

const app = createApp(App)

app
  .use(pinia)
  .use(store)
  .use(router)
  .directive('tooltip', vTooltip)
  .directive('mask', vMask)
  .mount('#app')

function refreshToken(storage: any) {
  storage.dispatch('auth/refreshToken')
}

const _requestHandler = async (
  config: InternalAxiosRequestConfig
): Promise<InternalAxiosRequestConfig> => {
  const authStore = useAuthStore()

  if (config.headers) {
    config.headers['Authorization'] = authStore.authHeader
  } else {
    config.headers = <AxiosRequestHeaders>{
      Authorization: authStore.authHeader
    }
  }
  return config;
};

axios.interceptors.request.use(
  async (request) => {
    const _request = await _requestHandler(request)
    return _request
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    const code = error?.response?.data?.code
    if (code === 'token_not_valid') {
      refreshToken(store)
    }
    return Promise.reject(error)
  }
)
