import MainService from '@/services/main.service'

export const main = {
  namespaced: true,
  state: {},
  actions: {
    getUserInfo({ commit }: any) {
      return MainService.getUserInfo().then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    getObject({ commit }: any, queryString: string) {
      return MainService.getObject(queryString).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    getProject({ commit }: any) {
      return MainService.getProject().then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    getReportingsHistory({ commit }: any) {
      return MainService.getReportingsHistory().then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    generateReport({ commit }: any, { id, data }: any) {
      return MainService.generateReport(id, data).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    generateDdsUnload({ commit }: any, data: any) {
      return MainService.generateDdsUnload(data).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    previewTemplate({ commit }: any, { id, data }: any) {
      return MainService.previewTemplate(id, data).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    }
  }
}
