import WorkerService from '@/services/worker.service'

export const worker = {
  namespaced: true,
  state: {},
  actions: {
    getSupportWorker({ commit }: any, queryString: string) {
      return WorkerService.getSupportWorker(queryString).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    getSingleWorker({ commit }: any, id: string | number) {
      return WorkerService.getSingleWorker(id).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    getProfileWorkerTransactions({ commit }: any, { id, queryString }: any) {
      return WorkerService.getProfileWorkerTransactions(id, queryString).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    workerFinancialReport({ commit }: any, { id, data }: any) {
      return WorkerService.workerFinancialReport(id, data).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    changeWorkerVerificationStatus({ commit }: any, { id, data }: any) {
      return WorkerService.changeWorkerVerificationStatus(id, data).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    }
  }
}
