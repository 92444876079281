<template>
  <ul class="flex flex-col overflow-hidden">
    <li>
      <h2 class="font-bold mb-2">{{ title }}</h2>
    </li>
    <template v-for="item in templates" :key="item.id">
      <li class="flex items-center mb-2">
        <div class="flex flex-col mr-4">
          <a :href="getFileUrl(item.file)" target="_blank" class="flex-grow overflow-hidden hover:underline mr-2"
            :title="item.name" :download="item.name">{{ item.name }}</a>
          <p v-if="item.id + '' === docToDelID" class="text-error">
            {{ errMsg }}
          </p>
        </div>

        <UButton v-if="!readonly" title="Редактировать" label="" size="sm"
          class="!bg-gray-100 border hover:opacity-60 !w-4 mr-3" @click="updateDoc(item)">
          <img class="min-w-fit" src="/icons/pencil.svg" alt="Редактировать" />
        </UButton>

        <UButton v-if="!readonly" title="Удалить" label="" size="sm" class="!bg-gray-100 border hover:opacity-60 !w-4"
          @click="deleteDoc(item)">
          <img class="min-w-fit" src="/icons/mistake.svg" alt="Редактировать" />
        </UButton>
      </li>
    </template>
    <li class="flex flex-col mt-auto mb-4">
      <UButton v-if="!readonly" label="Добавить"
        class="px-2 !justify-between overflow-hidden !min-w-[200px] w-full !h-[44px]" @click="addNewDoc" />
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { UButton } from 'unit-uikit'

export default defineComponent({
  components: {
    UButton
  },
  emits: ['addNewDoc', 'updateDoc', 'deleteDoc'],
  props: {
    title: String,
    templates: {
      type: Array as any,
      default: []
    },
    readonly: {
      type: Boolean,
      default: false
    },
    errMsg: {
      type: String
    },
    docToDelID: {
      type: String
    }
  },
  methods: {
    getFileUrl(url: string) {
      const mediaURL = process.env.VUE_APP_URL + '/media/'
      return `${mediaURL}${url}`
    },
    updateDoc(item: any) {
      this.$emit('updateDoc', item)
    },
    addNewDoc() {
      this.$emit('addNewDoc')
    },
    deleteDoc(item: any) {
      this.$emit('deleteDoc', item)
    }
  }
})
</script>
