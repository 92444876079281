import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5950fba5"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = {
  key: 1,
  class: "documents-link",
  style: {"cursor":"not-allowed"}
}
const _hoisted_3 = {
  key: 2,
  style: {"font-size":"10px","line-height":"16px"}
}
const _hoisted_4 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UButton = _resolveComponent("UButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex items-start", _ctx.getDocumentStatus(_ctx.status)])
  }, [
    _createElementVNode("div", null, [
      (_ctx.document?.file)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            class: "documents-link",
            href: _ctx.getDownloadLink(_ctx.document?.file),
            target: "_blank"
          }, _toDisplayString(_ctx.title), 9, _hoisted_1))
        : (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.title), 1)),
      (_ctx.document?.id)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, "№" + _toDisplayString(_ctx.document.id), 1))
        : _createCommentVNode("", true)
    ]),
    (_ctx.document?.status)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.showBtn)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                class: "ml-3",
                href: _ctx.getCertificateLink(_ctx.document),
                target: "_blank"
              }, [
                _createVNode(_component_UButton, {
                  size: "sm",
                  label: _ctx.getTitle(_ctx.status)
                }, null, 8, ["label"])
              ], 8, _hoisted_4))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ], 2))
}