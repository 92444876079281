import axios from 'axios'
import { API } from '@/utils/API'

class CompaniesService {
  getCompanyObjects(id: any) {
    return axios
      .get(API.GET_COMPANY_OBJECTS(id))
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  getEntityRecord(id: any) {
    return axios
      .get(API.GET_ENTITY_RECORD(id))
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  getCompanyProcurations(id: string | number, status: string) {
    return axios
      .get(API.GET_COMPANY_PROCURATIONS(id) + status)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  getCustomersList(queryString: string) {
    return axios
      .get(API.GET_CUSTOMERS_LIST + queryString)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  getCompanyObjectAgreements(id: string | number, queryString: string) {
    return axios
      .get(API.GET_COMPANY_OBJECT_AGREEMENTS(id) + queryString)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  getCompaniesList(status: string) {
    return axios
      .get(API.GET_COMPANIES_LIST + status)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  getWorkersInCompany(id: string | number, queryString: string) {
    return axios
      .get(API.GET_WORKERS_IN_COMPANY(id) + queryString)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  getOtherWorkers(id: string | number, queryString: string) {
    return axios
      .get(API.GET_OTHER_WORKERS(id) + queryString)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  getEntitiesList(queryString: string) {
    return axios
      .get(API.GET_ENTITIES_LIST + queryString)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  getObjectAgreement(companyId: string | number, objectId: string | number) {
    return axios
      .get(API.GET_OBJECT_AGREEMENT(companyId, objectId))
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  getProcuration(id: string | number) {
    return axios
      .get(API.GET_PROCURATION(id))
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  getCompanyProcurationRepresentatives(id: string | number) {
    return axios
      .get(API.GET_COMPANY_PROCURATIONS_REPRESENTATIVES(id))
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  getCompanyInfo(id: string | number) {
    return axios
      .get(API.GET_COMPANY_INFO(id))
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  getCheckList(queryString: string) {
    return axios
      .get(API.GET_CHECK_LIST + queryString)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  getCoworker(id: string | number) {
    return axios
      .get(API.GET_COWORKER(id))
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  getSupportCoworker(queryString: string) {
    return axios
      .get(API.SUPPORT_COWORKER + queryString)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  updateProcuration(id: string | number, payload: any) {
    return axios
      .patch(API.UPDATE_PROCURATION(id), payload)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  updateObjectAgreement(companyId: string | number, objectId: string | number, payload: any) {
    return axios
      .patch(API.UPDATE_OBJECT_AGREEMENT(companyId, objectId), payload)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  removeUser(data: any) {
    return axios
      .delete(API.REMOVE_CUSTOMER_FROM_COMPANY, {
        data: {
          company_id: data.company_id,
          user_id: data.user_id
        }
      })
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  addUser(data: any) {
    return axios
      .post(API.ADD_CUSTOMER_TO_COMPANY, {
        company_id: data.company_id,
        user_id: data.user_id
      })
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  updateUNITAgreement(id: string | number, payload: any) {
    return axios
      .patch(API.UPDATE_UNIT_AGREEMENT(id), payload)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  addWorkerToCompany(data: any) {
    return axios
      .post(API.ADD_WORKER_TO_COMPANY(data.id), { workers: data.workers })
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  removeWorkerFromCompany(data: any) {
    return axios
      .delete(API.REMOVE_WORKER_FROM_COMPANY(data.id), { data: { workers: data.workers } })
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  newProcuration(formData: any) {
    return axios
      .post(API.NEW_PROCURATION, formData, {
        headers: Object.assign({
          'Content-Type': 'multipart/form-data'
        })
      })
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  updateEntityAccountRepresentative(id: string | number, data: any) {
    return axios
      .patch(API.UPDATE_ENTITY_ACCOUNT_REPRESENTATIVE(id), data)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  updateEntityAccountDetails(id: string | number, data: any) {
    return axios
      .patch(API.UPDATE_ENTITY_ACCOUNT_DETAILS(id), data)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  updateEntityRecord(id: string | number, data: any) {
    return axios
      .patch(API.UPDATE_ENTITY_RECORD(id), data)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  updateEntityAccountConditions(id: string | number, data: any) {
    return axios
      .patch(API.UPDATE_ENTITY_ACCOUNT_CONDITIONS(id), data)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  uploadEntityCheckList(formData: any) {
    return axios
      .post(API.UPLOAD_ENTITY_CHECK_LIST, formData, {
        headers: Object.assign({
          'Content-Type': 'multipart/form-data'
        })
      })
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  updateEntitySigner(data: any) {
    return axios
      .post(API.UPDATE_ENTITY_SIGNER(data.id), { current_signer: data.current_signer })
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  updateCoworker(user: any, data: any) {
    return axios
      .patch(API.COWORKER(user), data)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  addCompanyCustomer(data: any) {
    return axios
      .post(API.ADD_COMPANY_CUSTOMER, data)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
}

export default new CompaniesService()
