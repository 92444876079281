import OrderService from '@/services/order.service'

export const order = {
  namespaced: true,
  state: {},
  actions: {
    getOrder({ commit }: any, queryString: string) {
      return OrderService.getOrder(queryString).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    getSingleOrder({ commit }: any, id: string | number) {
      return OrderService.getSingleOrder(id).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    getSuborderByOrder({ commit }: any, id: string | number) {
      return OrderService.getSuborderByOrder(id).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    getUnitTypes({ commit }: any) {
      return OrderService.getUnitTypes().then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    getOrderDocuments({ commit }: any, id: string | number) {
      return OrderService.getOrderDocuments(id).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    updateSingleDoc({ commit }: any, { doc, data }: any) {
      return OrderService.updateSingleDoc(doc, data).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    updateSingleOrder({ commit }: any, { id, data }: any) {
      return OrderService.updateSingleOrder(id, data).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    updateSuborderStatus({ commit }: any, { id, data }: any) {
      return OrderService.updateSuborderStatus(id, data).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    }
  }
}
