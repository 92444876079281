<script lang="ts" setup>
import { ref, Ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { UTable, UTooltip } from 'unit-uikit'
import { TServerTransation } from '@/types'
import { formatDate, formatPrice } from '@/utils/helpers'
import { ETransactionsStatus } from '@/types/api-values'
import { TRANSACTION_COLORS, TRANSACTION_STATUS } from '@/utils/consts'

const store = useStore()
const route = useRoute()

const loading = ref(true)
const transactions = ref([]) as Ref<TServerTransation[]>

const getStatusColor = (status: ETransactionsStatus) => {
  return status && TRANSACTION_COLORS[status] ? TRANSACTION_COLORS[status] : '#27BBDC'
}

const formatStatus = (status: ETransactionsStatus) => {
  return status && TRANSACTION_STATUS[status] ? TRANSACTION_STATUS[status] : ''
}

const getFileUrl = (file: any) => {
  const URL_CLEAR = process.env.VUE_APP_URL
  if (file) {
    return `${URL_CLEAR}/media/${file.replace('media/', '')}`
  }
  return ''
}

const scrollData = {
  count: 0,
  requestCount: 0,
  size: 20,
  page: 1
}

function normalizeTransaction(transaction: any) {
  return {
    id: transaction.id,
    status: transaction.status,
    withholding_tax: transaction.withholding_tax,
    bill_uuid: transaction.bill_uuid,
    company: transaction.company,
    payment: {
      id: transaction.id,
      created: transaction.created,
      amount: transaction.amount,
      commission: transaction.commission,
      suborder: transaction.suborder,
      check_image: transaction.check_image
    }
  }
}

const loadPayments = async () => {
  if (transactions.value.length < scrollData.count) {
    scrollData.page++
    const queryString = `?page=${scrollData.page}&page_size=${scrollData.size}&ordering=-created&status=paid`
    const id = route.params.id as string

    const response = await store.dispatch('worker/getProfileWorkerTransactions', { id: id, queryString: queryString })

    const data = response
    if (data.results && data.count) {
      scrollData.count = response.count
      const _transactions = response.results.map(normalizeTransaction)
      transactions.value.push(..._transactions)
    }
  }
}

store
  .dispatch('worker/getProfileWorkerTransactions', { id: route.params.id, queryString: '?page_size=20&ordering=-created&status=paid' })
  .then((response: any) => {
    scrollData.count = response.count
    const _transactions = response.results?.map(normalizeTransaction) || []
    transactions.value.push(..._transactions)
    loading.value = false
  })
  .catch((e: any) => {
    console.error(e)
    loading.value = false
  })
</script>

<template>
  <UTable colspan="7" :callback="loadPayments">
    <template #thead>
      <tr>
        <th>№ Номер платежа</th>
        <th>Сумма операции и комиссия</th>
        <th>Заказ и тип операции</th>
        <th>Компания</th>
        <th>ID транзакции</th>
        <th>Статус операции</th>
        <th>Чек</th>
      </tr>
    </template>
    <template #tbody>
      <tr v-for="item of transactions" :key="item.id" class="bg-white">
        <td>
          <span>№{{ item.payment?.id }}</span>
          <p class="heading-gray-3">{{ formatDate(item.payment?.created) }}</p>
        </td>
        <td>
          <div class="flex">
            <UTooltip
              v-if="item.withholding_tax"
              percent
              class="!static mr-3 relative self-center"
              tooltip="Платеж проходит с удержанием налога за исполнителя"
            />
            <div>
              <span class="break-normal">{{ formatPrice(item.payment?.amount) }}&nbsp;₽</span>
              <p class="heading-gray-3">{{ formatPrice(item.payment?.commission) }}&nbsp;₽</p>
            </div>
          </div>
        </td>
        <td>
          <span>
            <router-link
              :to="{
                name: 'order.info',
                params: {
                  id: item.payment?.suborder?.order
                }
              }"
              >Заказ №{{ item.payment?.suborder?.id }}</router-link
            >
            к договору №{{ item.payment?.suborder?.agreement }}
          </span>
          <p class="heading-gray-3">{{ item.payment?.suborder?.name || item.payment?.suborder?.initial_name }}</p>
        </td>
        <td>
          {{ item.company?.name || '' }}
        </td>
        <th>
          {{ item.bill_uuid }}
        </th>
        <td
          :style="{
            color: getStatusColor(item.status)
          }"
        >
          {{ formatStatus(item.status) }}
          <p class="heading-gray-3">{{ formatDate(item.payment.created) }}</p>
        </td>
        <td>
          <a
            :href="getFileUrl(item.payment?.check_image)"
            target="_blank"
            :class="{
              checkLink: true,
              isDisabled: !item.payment?.check_image || item.status !== ETransactionsStatus.paid
            }"
          >
            <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M21.8357 5.55533C21.6394 5.32876 16.916 0 11 0C5.08399 0 0.360701 5.32876 0.164291 5.55533C-0.0547636 5.80858 -0.0547636 6.19142 0.164291 6.44467C0.360701 6.67124 5.08408 12 11 12C16.916 12 21.6393 6.67124 21.8357 6.44467C22.0548 6.19142 22.0548 5.80858 21.8357 5.55533ZM11 10.6667C8.51251 10.6667 6.4883 8.57293 6.4883 6C6.4883 3.42707 8.51251 1.33333 11 1.33333C13.4875 1.33333 15.5117 3.42707 15.5117 6C15.5117 8.57293 13.4875 10.6667 11 10.6667Z"
                fill="currentColor"
              />
            </svg>
          </a>
        </td>
      </tr>
    </template>
  </UTable>
</template>

<style lang="postcss" scoped>
.checkLink {
  @apply text-[#6d7487] hover:text-black hover:cursor-pointer;

  &.isDisabled {
    @apply text-[#dbdee5] hover:text-[#dbdee5] hover:cursor-not-allowed;
  }
}
</style>
