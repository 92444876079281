import { createStore } from 'vuex'
import { auth } from './auth.module'
import { companies } from './companies.module'
import { bills } from './bills.module'
import { main } from './main.module'
import { order } from './order.module'
import { worker } from './worker.module'

export default createStore({
  state: {
    targetProject: null,
    newProjectMaxBudget: null,
    targetObject: null,
    billRegisterName: null,
    filters: {
      project: null,
      object: null
    },
    isPaymentFinish: false
  },
  mutations: {},
  actions: {},
  modules: {
    auth,
    companies,
    bills,
    main,
    order,
    worker
  }
})
