import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9ba476e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "overlay"
}
const _hoisted_2 = { class: "modal" }
const _hoisted_3 = { class: "modal-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UButton = _resolveComponent("UButton")!

  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("header", null, [
            _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.title), 1)
          ]),
          _createVNode(_component_UButton, {
            class: "!w-full",
            label: _ctx.textOk,
            onClick: _ctx.successAction
          }, null, 8, ["label", "onClick"])
        ])
      ]))
    : _createCommentVNode("", true)
}