import axios from 'axios'
import { API } from '@/utils/API'

class WorkerService {
  getSupportWorker(queryString: string) {
    return axios
      .get(API.SUPPORT_WORKER + queryString)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }

  getSingleWorker(id: string | number) {
    return axios
      .get(API.GET_SINGLE_WORKER(id))
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }

  getProfileWorkerTransactions(id: string | number, queryString: string) {
    return axios
      .get(API.PROFILE_WORKER_TRANSACTIONS(id) + queryString)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }

  workerFinancialReport(id: string | number, data: any) {
    return axios
      .post(API.WORKER_FINANCIAL_REPORT(id), data)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }

  changeWorkerVerificationStatus(id: string | number, data: any) {
    return axios
      .patch(API.CHANGE_WORKER_VERIFICATION_STATUS(id), data)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }

  patchWorker(id: string | number, data: any) {
    return axios
      .patch(API.CHANGE_WORKER_VERIFICATION_STATUS(id), data)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }

  getSpecializations() {
    return axios
      .get(API.SPECIALIZATIONS)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
}
export default new WorkerService()
