<template>
  <div :class="{ '!hidden': hideModal }" class="overlay !justify-end md:!justify-center" v-if="show">
    <div class="modal">
      <button type="button" class="absolute modal-close-btn" @click="closeModal">+</button>
      <header>
        <h3 class="-title mb-4">{{ title }} {{ templateName }}</h3>
        <p v-if="message" class="modal-description">{{ message }}</p>
      </header>
      <div class="-content">
        <UFileDrop v-if="!file" acceptTypes=".docx" :show-btn="false" @add-file="dropFile" />

        <template v-if="!file">
          <div class="errors-wrap" v-if="errors">
            <ul v-for="(err, index) in errors" :key="index">
              <li>{{ err }}</li>
            </ul>
          </div>
        </template>
        <template v-else>
          <UInput class="!max-w-full !w-full mb-4" label="Имя шаблона" :value="name" v-model="name" />
          <UInput class="!max-w-full !w-full mb-4" label="Документ" :value="file.name" :readonly="true">
            <template #icon>
              <span class="remove-icon" @click="cancelFile">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                    fill="#C6C6C6" />
                </svg>
              </span>
            </template>
          </UInput>
        </template>

        <div class="hidden md:flex justify-end">
          <UButton label="Отмена" color="secondary" class="mr-5" @click="closeModal" />
          <UButton :label="successBtnLabel" :disabled="!file && !loading" @click="uploadTemplatePreview" />
        </div>
        <UButton v-if="file" label="Добавить" :disabled="!file && !loading" class="mt-5 !w-full md:!hidden"
          @click="uploadTemplatePreview" />
      </div>
    </div>
  </div>
  <Loader v-if="loading" />
  <UModal class="preview-modal" :show="showDocPreview" title="Предпросмотр шаблона" @success="showDocPreview = false"
    @cancel="closeModal">
    <template #content>
      <vue-pdf-embed @rendered="loading = false" width="600" :source="`data:application/pdf;base64,${docSource}`" />
    </template>
    <template #buttons>
      <div class="flex justify-between">
        <UButton class="!w-full mr-3" @click="closeModal" label="Отмена" color="secondary" />
        <UButton class="!w-full" @click="loadFileToServer" label="Подтвердить" />
      </div>
    </template>
  </UModal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ETemplateTypes } from '@/types/api-values'
import { UButton, UInput, UModal, UFileDrop } from 'unit-uikit'
import Loader from '@/components/Loader/Loader.vue'
import VuePdfEmbed from 'vue-pdf-embed'
import { jsonToFormData } from '@/utils/jsonToForm'

export default defineComponent({
  components: {
    UButton,
    UInput,
    UModal,
    Loader,
    VuePdfEmbed,
    UFileDrop
  },
  data() {
    const date = new Date()
    const name = `Шаблон_${date.getUTCMonth()}_${date.getUTCFullYear()}`
    const doc = this.doc as any
    return {
      errors: [],
      loading: false,
      file: doc?.file ? { name: doc.name } : null,
      name: doc?.name || name,
      showDocPreview: false,
      hideModal: false,
      docSource: ''
    }
  },
  emits: ['successModal', 'closeModal'],
  props: {
    show: Boolean,
    title: String,
    message: String,
    doc: Object,
    template: ETemplateTypes as any,
    successBtnLabel: {
      type: String,
      default: 'Добавить'
    }
  },
  methods: {
    dropFile(f: File) {
      this.file = f
      // this.addFile()
    },
    addFile($event: any, flag: boolean) {
      this.errors = []
      const file = flag === true ? $event && $event[0] : $event.target.files && $event.target.files[0]
      const fileSize = file ? file.size / 1024 / 1024 : 0
      if (fileSize > 32) {
        // @ts-ignore

        this.errors.push('Размер файла не должен превышать 32МБ')
      } else if (file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        // @ts-ignore

        this.errors.push('Возможно загружать только файлы формата DOCX')
      } else {
        this.file = file
      }
    },
    cancelFile() {
      this.file = null
    },

    closeModal() {
      this.cancelFile()
      this.showDocPreview = false
      this.$emit('closeModal')
    },
    uploadTemplatePreview() {
      this.loading = true
      this.showDocPreview = true
      this.hideModal = true

      if (!this.doc) {
        const payload = { file: this.file }

        const formData = jsonToFormData(payload)

        const companyID = this.$route.params.id

        // @ts-ignore
        this.$store
          .dispatch('main/previewTemplate', { id: companyID, data: formData })
          .then((res: any) => {
            this.docSource = res.file
          })
          .catch((err: any) => {
            console.error(err)
            this.loading = false
          })
      } else {
        this.showDocPreview = false
        this.loadFileToServer()
      }
    },
    loadFileToServer() {
      const data = {
        id: this.doc?.id,
        name: this.name,
        category: this.template
      } as any

      if (this.file instanceof File) {
        data.file = this.file
      }

      this.$emit('successModal', data)
    },
    getFileUrl(item: any) {
      const URL_CLEAR = process.env.VUE_APP_URL
      const file = item
      if (file) {
        return `${URL_CLEAR}/media/${file.replace('media/', '')}`
      }
      return ''
    }
  },
  computed: {
    templateName() {
      switch (this.template) {
        case ETemplateTypes.agreement:
          return 'договора'
        case ETemplateTypes.task:
          return 'задания'
        case ETemplateTypes.certificate:
          return 'акта'

        default:
          return ''
      }
    }
  }
})
</script>

<style lang="postcss" scoped>
.modal {
  box-shadow: 0px 0px 24px rgba(114, 114, 114, 0.2);
  @apply bg-white p-7 rounded-lg flex flex-col;
  max-width: 560px;
  gap: 32px;

  .-title {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 140%;
    color: var(--black);
  }

  .-buttons {
    @apply flex;
    gap: 20px;
  }

  .-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

.preview-modal {
  & :deep(.modal) {
    overflow: hidden;
    @apply w-[700px] max-w-fit;
  }

  & :deep(.-content) {
    overflow: auto;
  }
}

.modal-description {
  @apply text-base text-grey;
}

.remove-icon {
  @apply w-[14px] h-[14px] flex justify-center items-center self-center mr-2 hover:cursor-pointer;
}

.select-file-btn {
  @apply rounded flex justify-center items-center bg-black text-white outline-none whitespace-nowrap;
  @apply h-[40px] text-sm px-7 w-auto md:text-base tracking-[0.5px] hover:cursor-pointer;

  &:disabled {
    @apply bg-[#DBDEE5];

    &:hover {
      @apply bg-[#DBDEE5] cursor-not-allowed;
    }
  }

  &:hover {
    @apply bg-[#71788B] text-white;
  }

  &:active {
    @apply bg-[#495164] text-white;
  }
}

.modal-close-btn {
  right: 15px;
  top: 15px;
  line-height: 16px;
  transform: rotate(45deg);
  font-weight: 300;
  font-size: 36px;
  text-align: center;
  @apply md:hidden;
}

.errors-wrap {
  ul li {
    @apply font-normal text-sm leading-6 text-left tracking-[0.5px] text-error;
  }
}
</style>
