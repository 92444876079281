<template>
  <h2 class="text-2xl font-bold mb-5">Банковские реквизиты</h2>
  <div class="flex mb-5">
    <UInput class="!max-w-none flex-grow basis-1/5 mr-4" label="Наименование банка" :value="bank" v-model="bank"
      @keypress.enter="updateCompanyInfo('bank')" @blurAction="updateCompanyInfo('bank')" :readonly="readonly">
      <template #icon>
        <p class="absolute top-full text-error text-xs">{{ bankError }}</p>
      </template>
    </UInput>
    <UInput class="!max-w-none flex-grow basis-1/5 mr-4" label="Расчетный счет" :value="checking_account"
      v-model="checking_account" @keypress.enter="updateCompanyInfo('checking_account')"
      @blurAction="updateCompanyInfo('checking_account')" :readonly="readonly">
      <template #icon>
        <p class="absolute top-full text-error text-xs">{{ checking_accountError }}</p>
      </template>
    </UInput>
    <UInput class="!max-w-none flex-grow basis-1/5 mr-4" label="Корреспондентский счет" :value="correspondent_account"
      v-model="correspondent_account" @keypress.enter="updateCompanyInfo('correspondent_account')"
      @blurAction="updateCompanyInfo('correspondent_account')" :readonly="readonly">
      <template #icon>
        <p class="absolute top-full text-error text-xs">{{ correspondent_accountError }}</p>
      </template>
    </UInput>
    <UInput class="!max-w-none flex-grow basis-1/5" label="БИК" :value="bik" v-model="bik"
      @keypress.enter="updateCompanyInfo('bik')" @blurAction="updateCompanyInfo('bik')" :readonly="readonly">
      <template #icon>
        <p class="absolute top-full text-error text-xs">{{ bikError }}</p>
      </template>
    </UInput>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { UInput } from 'unit-uikit'

export default defineComponent({
  components: {
    UInput
  },
  data() {
    const info = this.info as any
    return {
      company: info?.company,
      bik: info?.bik,
      bikError: '',
      bank: info?.bank,
      bankError: '',
      checking_account: info?.account,
      checking_accountError: '',
      correspondent_account: info?.correspondent,
      correspondent_accountError: ''
    }
  },
  props: {
    info: { type: Object },
    readonly: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    updateCompanyInfo(field: string) {
      if (this.readonly) {
        return
      }
      // @ts-ignore

      this[field + 'Error'] = ''
      const id = this.$route?.params?.id
      const data = {
        bik: this.bik,
        bank: this.bank,
        checking_account: this.checking_account,
        correspondent_account: this.correspondent_account,
        company: this.company
      }

      // @ts-ignore
      return this.$store
        .dispatch('companies/updateEntityAccountDetails', { id, data })
        .then(() => {
          // @ts-ignore

          this[field + 'Error'] = ''
        })
        .catch((err: any) => {
          console.error(err)
          const data = err.response.data
          const errText = data[field]
          // @ts-ignore

          this[field + 'Error'] = errText && errText.join(', ')
        })
    }
  }
})
</script>

