<script setup lang="ts">
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import PriceItem from '@/components/Prices/PriceItem.vue'

const store = useStore()
const route = useRoute()

const id = route.params?.id as string

const deposite = ref('')
const reserve = ref('')
const free = ref('')

if (id) {
  store
    .dispatch('companies/getCompanyInfo', id)
    .then((response) => {
      deposite.value = response.company_deposit
      reserve.value = response.total_reserve
      free.value = response.company_deposit - response.total_reserve + ''
    })
    .catch((e) => {
      console.error(e)
    })
}
</script>

<template>
  <h2 class="text-2xl font-bold mb-5">Бюджет</h2>
  <div class="flex mb-5">
    <PriceItem class="!max-w-none flex-grow basis-1/5 mr-4" :price="deposite" title="Депозит компании"
      tooltip="Сумма на счету компании в данный момент времени" />
    <PriceItem class="!max-w-none flex-grow basis-1/5 mr-4" :price="reserve" title="Резерв всех заказов"
      tooltip="Сумма, на которую созданы заказы и распределены неоплаченные задания" />
    <PriceItem class="!max-w-none flex-grow basis-1/5 mr-4" :price="free" title="Свободный остаток"
      tooltip="Сумма, в рамках которой возможно создать заказы и распределить задания" />
    <div class="flex-grow basis-1/5"></div>
  </div>
</template>

<style lang="postcss" scoped></style>
