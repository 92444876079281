<script setup lang="ts">
import { ref } from 'vue'

import MainPageHeader from '@/components/MainPageHeader/MainPageHeader.vue'
import { UTabs } from 'unit-uikit'

const tabs = ref([
  {
    routeName: 'reportings.add',
    label: 'Отчетность'
  },
  {
    routeName: 'reportings.payments',
    label: 'Выплаты по компаниям'
  },
  {
    routeName: 'reportings.history',
    label: 'История экспорта'
  },
  {
    routeName: 'reportings.listaccounts',
    label: 'Платежи'
  }
])
</script>
<template>
  <main-page-header title="Отчетность" class="page__title"></main-page-header>
  <u-tabs :tabs="tabs" />
  <router-view></router-view>
</template>
