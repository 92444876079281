import { createRouter, createWebHistory, RouteRecordRaw, RouteLocationNormalized, NavigationGuardNext, RouteRecordNormalized } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import store from '../store'

import { EUserRoles } from '@/types'

import Login from '@/views/Registration/Login.vue'
import Main from '@/views/Main.vue'

import Workers from '@/views/Workers/index.vue'
import Worker from '@/views/Workers/Worker/index.vue'
import WorkerInfo from '@/views/Workers/Worker/WorkerInfo.vue'
import WorkerHistory from '@/views/Workers/Worker/WorkerHistory.vue'
import WorkersList from '@/views/Workers/WorkersList.vue'

import Orders from '@/views/Orders/Orders.vue'
import OrdersList from '@/views/Orders/OrdersComponents/OrdersList.vue'

import Order from '@/views/Orders/Order.vue'
import OrderInfo from '@/views/Orders/OrderInfo.vue'
import OrderDocs from '@/views/Orders/OrderDocs.vue'
import OrderTasks from '@/views/Orders/OrderTasks.vue'

import index from '@/views/Deposit/index.vue'
import DepositsList from '@/views/Deposit/DepositsList.vue'
import Deposit from '@/views/Deposit/Deposit.vue'
import BillsPayments from '@/views/Deposit/BillsPayments.vue'

import Reportings from '@/views/Reportings/index.vue'
import ReportingsAdd from '@/views/Reportings/ReportingsAdd.vue'
import ReportingsHistory from '@/views/Reportings/ReportingsHistory.vue'
import ReportingsPayments from '@/views/Reportings/ReportingsPayments.vue'
import ListAccounts from '@/views/Reportings/ListAccounts.vue'

import CompaniesList from '@/views/Companies/CompaniesList.vue'
import CompaniesVerified from '@/views/Companies/VerifiedCompanies.vue'
import CompaniesNotVerified from '@/views/Companies/NotVerifiedCompanies.vue'
import CompanyInfo from '@/views/Companies/CompanyInfo.vue'
import CompanyAccesses from '@/views/Companies/CompanyAccesses.vue'
import CompanyInfoParent from '@/views/Companies/CompanyInfoParent.vue'
import CompanyObjects from '@/views/Companies/CompanyObjects.vue'

import Staffs from '@/views/Companies/Staff/index.vue'
import StaffList from '@/views/Companies/Staff/StaffList.vue'
import StaffInfo from '@/views/Companies/Staff/StaffInfo.vue'

import Page404 from '@/views/404.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/',
    name: 'main',
    component: Main,
    meta: {
      requiresAuth: true
    },
    redirect: {
      name: 'workers.verified'
    },
    children: [
      {
        path: '/workers',
        name: 'workers',
        component: Workers,
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: '',
            name: 'workers.verified',
            component: WorkersList
          },
          {
            path: 'not_verified',
            name: 'workers.not_verified',
            component: WorkersList
          }
        ]
      },
      {
        path: '/worker/:id',
        name: 'workers.worker',
        component: Worker,
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: '',
            name: 'workers.worker.info',
            component: WorkerInfo
          },
          {
            path: 'history',
            name: 'worker.history',
            component: WorkerHistory
          }
        ]
      },
      {
        path: 'orders',
        name: 'orders',
        component: Orders,
        beforeEnter() {
          store.state.filters.project = null
          store.state.filters.object = null
        },
        children: [
          {
            path: '',
            name: 'orders.list',
            component: OrdersList
          }
        ]
      },
      {
        path: 'order/:id',
        name: 'order',
        component: Order,
        children: [
          {
            // Info page for ordes
            path: '',
            name: 'order.info',
            component: OrderInfo
          },
          {
            // Info page for ordes
            path: 'docs',
            name: 'order.docs',
            component: OrderDocs
          },
          {
            // Info page for ordes
            path: 'tasks',
            name: 'order.tasks',
            component: OrderTasks
          }
        ]
      },
      {
        path: 'deposits',
        name: 'deposits',
        component: index,
        children: [
          {
            path: '',
            name: 'deposit.list',
            component: DepositsList,
            beforeEnter() {
              const authStore = useAuthStore()
              if (authStore.userRole === EUserRoles.support) {
                return {
                  name: 'deposit.billspayments'
                }
              }
            }
          },
          {
            path: '/deposit/:id',
            name: 'deposit.id',
            component: Deposit
          },
          {
            path: 'billspayments',
            name: 'deposit.billspayments',
            component: BillsPayments
          }
        ]
      },
      {
        path: 'reportings',
        name: 'reportings',
        component: Reportings,
        children: [
          {
            path: '',
            name: 'reportings.add',
            component: ReportingsAdd
          },
          {
            path: 'history',
            name: 'reportings.history',
            component: ReportingsHistory
          },
          {
            path: 'payments',
            name: 'reportings.payments',
            component: ReportingsPayments
          },
          {
            path: 'listaccounts',
            name: 'reportings.listaccounts',
            component: ListAccounts
          }
        ]
      },
      {
        path: 'companies',
        name: 'companies.list',
        component: CompaniesList,
        children: [
          {
            path: '',
            name: 'companies.verified',
            component: CompaniesVerified
          },
          {
            path: 'not_verified',
            name: 'companies.not_verified',
            component: CompaniesNotVerified
          }
        ]
      },
      {
        path: 'companies/:id',
        name: 'companies.parent',
        component: CompanyInfoParent,
        children: [
          {
            path: '',
            name: 'companies.info',
            component: CompanyInfo
          },
          {
            path: 'accesses',
            name: 'companies.accesses',
            component: CompanyAccesses
          },
          {
            path: 'procuration',
            name: 'companies.procuration',
            component: () => import('@/views/Companies/CompanyProcurations.vue')
          },
          {
            path: 'object_agreements',
            name: 'companies.objects',
            component: CompanyObjects
          },
          {
            path: 'agents',
            name: 'companies.agents',
            component: () => import('@/views/Companies/CompanyInfoAgents.vue')
          },
          {
            path: 'workers',
            name: 'companies.workers',
            component: () => import('@/views/Companies/CompanyWorkers.vue')
          },
          {
            path: 'add',
            name: 'procuration.new',
            component: () => import('@/views/Companies/ProcurationNew.vue')
          }
        ]
      },
      {
        path: 'object_agreements/:id',
        name: 'objects',
        component: () => import('@/views/Companies/ObjAgreementEdit.vue')
      },
      {
        path: 'procuration/:id',
        name: 'procuration',
        component: () => import('@/views/Companies/Procuration.vue')
      },
      {
        path: 'staff',
        name: 'staff.list',
        component: Staffs,
        children: [
          {
            path: '',
            name: 'staff.verified',
            component: StaffList
          },
          {
            path: 'not_verified',
            name: 'staff.not_verified',
            component: StaffList
          }
        ]
      },
      {
        path: 'staff/:id',
        name: 'staff',
        component: StaffInfo
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    component: Page404
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  if (to.matched.some((record: RouteRecordNormalized) => record.meta.requiresAuth)) {
    const authStore = useAuthStore()
    if (authStore.tokenExpired) {
      store.dispatch('auth/refreshToken')
        .then(() => {
          next()
        })
        .catch((e) => {
          next({
            name: 'login'
          })
        })
      return
    } else {
      if (to.name === 'login' && authStore.loggedIn) {
        next({
          path: '/'
        })
        return
      }
      if (!authStore.loggedIn) {
        next({
          name: 'login'
        })
        return
      }
      next()
      return
    }
  }
  next()
  // Must be used within the function!
})

export default router
