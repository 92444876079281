<template>
  <div class="overlay justify-center" v-if="show">
    <div class="modal">
      <header>
        <h3 class="-title mb-4">{{ title }}</h3>
        <p class="modal-description">{{ message }}</p>
      </header>
      <div class="-content">
        <div class="space-y-5">
          <div>
            <UInput class="!max-w-none flex-grow" label="Номер телефона" :value="username" v-model="username"
              mask="phone">
              <template #icon>
                <p class="absolute top-full text-error z-[1] text-xs">{{ usernameError }}</p>
              </template>
            </UInput>
          </div>
        </div>

        <div class="mt-5 flex justify-end">
          <UButton label="Отмена" color="secondary" class="mr-5" @click="closeModal" />
          <UButton :label="successBtnLabel" :disabled="addBtnDisabled && !loading" @click="updatePhone" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { UInput, UButton } from 'unit-uikit'

export default defineComponent({
  components: {
    UInput,
    UButton
  },
  data() {
    const username = this.phone || ''
    return {
      loading: false,
      usernameError: '',
      username
    }
  },
  props: {
    show: Boolean,
    title: String,
    message: String,
    phone: String,
    successBtnLabel: {
      type: String,
      default: 'Сохранить'
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
    updatePhone() {
      const id = this.$route.params.id
      this.loading = true

      this.usernameError = ''
      const username = this.username.replace(/-/g, '')

      // @ts-ignore
      this.$store
        .dispatch('worker/changeWorkerVerificationStatus', { id: id, data: { username } })
        .then(() => {
          this.loading = false
          this.$emit('successModal')
        })
        .catch((e: any) => {
          const data = e.response.data
          const errText = data.username && data.username.join(', ')
          this.loading = false

          if (errText) {
            this.usernameError = errText
          } else {
            this.$emit('successModal', e)
          }
        })
    }
  },
  computed: {
    addBtnDisabled() {
      return this.username.replace(/-/g, '').length !== 12
    }
  }
})
</script>

<style lang="postcss" scoped>
.modal {
  box-shadow: 0px 0px 24px rgba(114, 114, 114, 0.2);
  @apply bg-white p-7 rounded-lg flex flex-col;
  max-width: 560px;
  gap: 32px;

  .-title {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 140%;
    color: var(--black);
  }

  .-buttons {
    @apply flex;
    gap: 20px;
  }

  .-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
</style>
