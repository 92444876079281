import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import jwtDecode from 'jwt-decode'

import { TToken, TTokenPayload } from '@/types'

const TOKEN_KEY = 'JWT_SUPPORT'

export const useAuthStore = defineStore('auth', () => {
  const stringJWT = localStorage.getItem(TOKEN_KEY)
  const jwt = ref((stringJWT && JSON.parse(stringJWT)) || null)

  const loggedIn = computed(() => {
    return jwt.value !== null
  })

  const authHeader = computed(() => {
    const accessToken = jwt.value?.access_token
    if (accessToken) {
      return `Bearer ${accessToken}`
    }
    return null
  })

  const userRole = computed(() => {
    const accessToken = jwt.value?.access_token
    if (accessToken) {
      const payload = jwtDecode(jwt.value.access_token) as TTokenPayload
      return payload.user_type
    }
    return null
  })

  const tokenExpired = computed(() => {
    const accessToken = jwt.value?.access_token
    if (accessToken) {
      const { exp } = jwtDecode(jwt.value.access_token) as TTokenPayload
      return !!(exp && exp * 1000 < Date.now())
    }
    return true
  })

  function $reset() {
    localStorage.removeItem(TOKEN_KEY)
    jwt.value = null
  }

  function saveJWT(token: TToken) {
    if (token?.access_token) {
      const _token = JSON.stringify(token)
      localStorage.setItem(TOKEN_KEY, _token)
      jwt.value = token
    }
  }

  return {
    jwt,
    saveJWT,
    loggedIn,
    authHeader,
    userRole,
    tokenExpired,
    $reset
  }
})
