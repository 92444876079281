import dayjs from 'dayjs'
import IMask from 'imask'

export function formatPrice(price: number | string) {
  const _price = ((price || 0) + '').replaceAll('.', ',').replaceAll(' ', '') || '0'
  const newPrice = IMask.pipe(_price, {
    mask: Number,
    scale: 2,
    thousandsSeparator: ' ',
    normalizeZeros: true,
    padFractionalZeros: true,
    radix: ',', // fractional delimiter
    mapToRadix: ['.']
  })
  return newPrice
}

export function formatDate(date: any, type?: string) {
  if (!type) {
    return dayjs(date).format('DD.MM.YY')
  } else if (type === 'fullYear') {
    return dayjs(date).format('DD.MM.YYYY') || ''
  }
}

export function formatToServerDate(value: string) {
  const [day, month, year] = value.split('.')
  if (day && month && year) {
    return `${year}-${month}-${day}`
  }
  return null
}
