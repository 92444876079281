<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import dayjs from 'dayjs'

import { formatToServerDate } from '@/utils/helpers'

import Loader from '@/components/Loader/Loader.vue'

import { UButton, UInput, UISelect, UMultiselect, USwitch, UICheckbox } from 'unit-uikit'
import router from '@/router'

const store = useStore()
const route = useRoute()
const loading = ref(false)

let companiesListOptions = ref([])
let objectsListOptions: any = ref([])
let selectedObjIDs: any = ref([])

const selectedObj = ref([])
const selectedCompany = ref('')

const company = ref('')
const dateStart = ref('')
const dateEnd = ref('')

const packageReports = ref(false)
const docsByWorkers = ref(false)
const isAgreementExists = ref(false)
const isCompanyObjectAgreementExists = ref(false)

onMounted(() => {
  store
    .dispatch('companies/getCompaniesList', '')
    .then((res: any) => {
      companiesListOptions.value = res
    })
    .catch((e: any) => {
      console.error(e)
    })

  let routeQueryCompanyId: any = route.query.companyId
  getCompanyObjects(routeQueryCompanyId)
})

const clearFilters = () => {
  selectedObj.value = []
  dateStart.value = ''
  dateEnd.value = ''
  packageReports.value = false
  docsByWorkers.value = false
}

const getSelectedObjIDs = () => {
  const selectedObjects = []
  const selectedObjectsIDs: any = []

  selectedObjects.push(selectedObj.value)
  selectedObjects.forEach((obj: any) => {
    obj.forEach((item: any) => {
      selectedObjectsIDs.push(item.id)
    })
  })

  selectedObjIDs.value = selectedObjIDs.value.length === objectsListOptions.value[0]?.options.length ? [] : selectedObjectsIDs
}

const getCompanyObjects = (companyID: string | number) => {
  if (companyID) {
    store
      .dispatch('companies/getCompanyObjects', companyID)
      .then((res: any) => {
        const objects = res

        let allObjectsWithLabelSelectAll = [
          {
            all: 'Выбрать все',
            options: [...objects]
          }
        ]

        objectsListOptions.value = allObjectsWithLabelSelectAll
      })
      .catch((e: any) => {
        console.error(e)
      })

    store
      .dispatch('companies/getEntityRecord', companyID)
      .then((res: any) => {
        if (res.agreement_with_unit_number && res.agreement_with_unit_date) {
          isAgreementExists.value = true
        } else {
          isAgreementExists.value = false
        }
      })
      .catch((e: any) => {
        console.error(e)
      })
    store
      .dispatch('companies/getCompanyObjectAgreements', { id: companyID, queryString: '' })
      .then((res: any) => {
        if (res.results.length) {
          isCompanyObjectAgreementExists.value = true
        } else {
          isCompanyObjectAgreementExists.value = false
        }
      })
      .catch((e: any) => {
        console.error(e)
      })
  }
}

const getSelectedCompanyID = () => {
  selectedObj.value = []
  company.value = selectedCompany.value
  let queryCompanyId: any = route.query.companyId ? route.query.companyId : company.value
  getCompanyObjects(queryCompanyId)
}

const generateReport = () => {
  loading.value = true

  const payload = {
    name: `Выгрузка_${dayjs(new Date()).format('DD.MM.YY_HH:mm:ss')}`,
    company: route.query.companyId ? route.query.companyId : company.value,
    objects: packageReports.value ? [] : selectedObjIDs.value,
    date_start: formatToServerDate(dateStart.value),
    date_end: formatToServerDate(dateEnd.value),
    all_company: packageReports.value,
    add_docs: docsByWorkers.value
  }

  let queryCompanyId: any = route.query.companyId ? route.query.companyId : company.value

  store
    .dispatch('main/generateReport', { id: queryCompanyId, data: payload })
    .then(() => {
      router.push('/reportings/history')
      loading.value = false
    })
    .catch((e: any) => {
      console.error(e)
      loading.value = false
    })
}

const isDisabled = computed(() => {
  if (!route.query.companyId) {
    if (!selectedCompany.value || !dateStart.value || !dateEnd.value) {
      return true
    } else {
      return false
    }
  } else {
    if (!dateStart.value || !dateEnd.value) {
      return true
    } else {
      return false
    }
  }
})
</script>
<template>
  <Loader v-if="loading" />
  <div class="flex flex-col overflow-hidden overflow-y-auto flex-grow">
    <div class="flex flex-col gap-8">
      <div class="flex gap-3 flex-wrap">
        <UISelect v-if="route.query.companyId" @change="getSelectedCompanyID()" large class="!w-[262px]"
          label="Наименование компании" unselectedLabel="Выберите компанию" placeholder="Наименование"
          :options="companiesListOptions" v-model="route.query.companyId" :value="route.query.companyId" />
        <UISelect v-else @change="getSelectedCompanyID()" large class="!w-[262px]" label="Наименование компании"
          unselectedLabel="Выберите компанию" placeholder="Наименование" :options="companiesListOptions"
          v-model="selectedCompany" :value="selectedCompany" />
        <div class="relative">
          <span
            class="absolute text-[0.75rem] text-[#C9CDDD] leading-5 top-[3px] left-2 whitespace-nowrap z-[99]">Наименование
            объекта</span>
          <UMultiselect :disabled="packageReports || (!selectedCompany && !route.query.companyId)"
            @close="getSelectedObjIDs()" v-model="selectedObj" :options="objectsListOptions" :close-on-select="false"
            :multiple="true" :searchable="true" class="!w-[262px]" placeholder="Наименование" label="name" track-by="id"
            group-values="options" group-label="all" :group-select="true" :showNoOptions="true">
            <template #noOptions>Список объектов пуст</template>
            <template #noResult>Ничего не найдено</template>
            <template #option="option">
              <span :title="option.option?.name || ''">{{ option.option?.name || 'Выбрать все' }}</span>
            </template>
          </UMultiselect>
        </div>
        <UInput v-model="dateStart" :value="dateStart" large class="!w-[160px]" label="Дата начала"
          placeholder="дд.мм.гггг" mask="date" />
        <UInput v-model="dateEnd" :value="dateEnd" large class="!w-[160px]" label="Дата окончания"
          placeholder="дд.мм.гггг" mask="date" />
        <USwitch class="package-reports" id="packageReports" v-model="packageReports" labelText="Единый пакет отчетов" />
        <UICheckbox class="flex items-center whitespace-nowrap" id="docsByWorkers" v-model="docsByWorkers"
          :value="docsByWorkers" label="Скачать документы по исполнителям" />
      </div>
    </div>
  </div>
  <div class="flex flex-col">
    <div class="flex gap-6 mb-3 self-end">
      <UButton label="Отмена" color="secondary" @click="clearFilters()" />
      <UButton :disabled="isDisabled || !isAgreementExists || !isCompanyObjectAgreementExists"
        label="Сформировать отчетность" @click="generateReport()" />
    </div>
    <p class="text-[#8491A5] text-xs text-right w-64 self-end">Сформированная отчетность находится во вкладке “История
      экспорта”</p>
  </div>
</template>
<style lang="postcss" scoped>
:deep(.multiselect__tags) {
  @apply h-14;
  padding: 10px 40px 0 8px;
}

:deep(.multiselect__select:before) {
  @apply top-4;
}

:deep(.multiselect__option--group.multiselect__option--highlight) {
  @apply bg-[#ededed]/60;
}

:deep(.multiselect__content .multiselect__element:first-child .multiselect__option:before) {
  @apply hidden;
}

:deep(.multiselect__content .multiselect__element:first-child .multiselect__option span) {
  @apply ml-0;
}

:deep(.multiselect__content-wrapper) {
  @apply w-fit min-w-full;
}

.package-reports {
  @apply h-14;

  & :deep(span) {
    @apply whitespace-nowrap;
  }

  & :deep(div:last-child) {
    @apply ml-2;
  }
}
</style>
