<template>
  <div>
    <main-page-header :title="title" class="page__title"></main-page-header>
  </div>

  <router-view></router-view>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MainPageHeader from '@/components/MainPageHeader/MainPageHeader.vue'

export default defineComponent({
  components: {
    MainPageHeader
  },
  data() {
    return {
      title: 'Реестр заказов',
      ready: false
    }
  }
})

</script>

<style lang="postcss" scoped>
.disabled-link:hover {
  cursor: not-allowed;
}
</style>
