<template>
  <div class="layout">
    <div class="registration-page">
      <div class="registration-page__half">
        <h3>UNIT</h3>
      </div>
      <div class="registration-page__half">
        <div class="registration-page__half--wrap">
          <template v-if="!showSMSCode">
            <div class="login-form">
              <UInput large class="!w-[360px] !max-w-[360px] mb-7" label="Мобильный телефон" :value="formatPhone"
                v-model="user.phone" mask="phone" @paste="handlePaste" @keydown="handleKeyDown" @keypress.enter="login" />
              <div class="error-block__message" style="margin-top: -30px; margin-bottom: 12px"
                v-if="v$.user.phone.$dirty && v$.user.phone.required.$invalid">
                Обязательное поле
              </div>
              <div class="error-block__message" style="margin-top: -30px; margin-bottom: 12px"
                v-if="v$.user.phone.$dirty && v$.user.phone.minLength.$invalid">
                Некорректная длина телефона
              </div>
              <div class="error-block__message" style="margin-top: -30px; margin-bottom: 12px"
                v-if="userAlreadyExists && !v$.user.phone.required.$invalid && !v$.user.phone.minLength.$invalid">
                Пользователя с таким номером не существует
              </div>
              <UButton label="Войти" class="btn__login btn-long" @click.prevent="login" />
            </div>
          </template>
          <template v-else>
            <div class="ankieta-page__description">
              <p>Мы отправили вам временный код для входа в платфрму, на номер {{ user.phone }}.</p>
              <p>Он потребуется для того, чтобы вы могли внести данные о компании и следить за статусом заявки.</p>
            </div>

            <div class="login-form self-start">
              <div class="error-block__message" v-if="codeError">Неверный код, попробуйте ещё раз</div>
              <input type="text" placeholder="Пароль из СМС" class="p-4 rounded-lg mb-3" v-model="user.code"
                @keypress.enter="loginConfirm" />
              <p class="login-form__no-sms__text">
                Не пришло СМС?
                <span v-if="timer">Отправить повторно через {{ timer }} с.</span>
                <span v-else class="btn-link" @click="sendSMS">Отправить повторно смс-код</span>
              </p>

              <div class="flex gap-3">
                <UButton label="Отмена" color="secondary" @click.prevent="showSMSCode = false" />
                <UButton label="Войти" @click.prevent="loginConfirm" />
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { UInput, UButton } from 'unit-uikit'

import useValidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default defineComponent({
  components: {
    UInput,
    UButton
  },
  props: {
    hideActions: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      v$: useValidate(),
      showSMSCode: false,
      userAlreadyExists: false,
      timer: 60,
      timeout: null as any,
      user: {
        phone: '',
        name: '',
        code: ''
      },
      codeError: false
    }
  },
  methods: {
    ...mapActions({
      auth: 'auth/login'
    }),
    sendSMS() {
      const user = this.user
      this.auth(user).then(
        () => {
          // Move to page for input sms code
          this.startTimer()
        },
        (error: unknown) => {
          console.log('error', error)
        }
      )
    },
    login() {
      this.v$.user.phone.$touch()

      if (this.v$.user.phone.$invalid) {
        return
      }

      const user = this.user
      if (user.phone.length === 17) user.phone = user.phone.slice(0, 16)

      // @ts-ignore
      this.$store
        .dispatch('auth/login', user)
        .then(
          () => {
            // Move to page for input sms code
            this.showSMSCode = true
            this.userAlreadyExists = false
            this.startTimer()
          },
          (error: unknown) => {
            this.userAlreadyExists = true
          }
        )
        .catch((err: any) => {
          this.userAlreadyExists = true
        })
    },
    loginConfirm() {
      const user = this.user
      // @ts-ignore
      this.$store.dispatch('auth/loginConfirm', user).then(
        () => {
          // Move to page for input sms code
          this.$router.push('/')
        },
        (error: any) => {
          this.codeError = true
          console.log('error', error.response.data)
        }
      )
    },
    startTimer() {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.timer = 60
      this.timeout = setInterval(() => {
        this.timer--
        if (this.timer <= 0) {
          clearTimeout(this.timeout)
          this.timeout = null
        }
      }, 1000)
    },
    handleKeyDown(evt: any) {
      if (evt.keyCode === 8 && (evt.target.selectionStart === 2 || evt.target.selectionStart === 3)) {
        evt.preventDefault()
      }
    },
    handlePaste(event: any) {
      event.preventDefault()
      let _pastePhone = (event.clipboardData || (window as any).clipboardData).getData('text').replace(/[^0-9]/g, '')

      if (_pastePhone.length >= 10 && _pastePhone.startsWith('8')) {
        _pastePhone = '7' + _pastePhone.slice(1)
      }

      this.user.phone = _pastePhone
    }
  },
  computed: {
    formatPhone() {
      if (this.user.phone.length === 0) {
        this.user.phone = '+7'
      }

      return this.user.phone
    }
  },
  validations() {
    return {
      user: {
        phone: { required, minLength: minLength(16) }
      }
    }
  },
  watch: {
    'user.code'() {
      this.codeError = false
    },
    'user.phone'() {
      this.userAlreadyExists = false
    }
  }
})

</script>

<style lang="postcss" scoped>
.registration-page {
  display: flex;
  height: 100vh;
  overflow: hidden;
  width: 100%;

  &__tabs {
    display: flex;
    width: 350px;
    justify-content: space-between;

    &--underline {
      width: 175px;
      height: 5px;
      background: #dfdfe4;
      margin-bottom: 40px;

      &.activeUnderline {
        background: black;
      }
    }

    &--title {
      color: #dfdfe4;
      font-size: 26px;
      line-height: 30px;
      margin-bottom: 15px;

      &.activeTitle {
        color: black;
      }
    }
  }

  &__half {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 88px;
      line-height: 28px;
      /* or 32% */

      display: flex;
      align-items: center;

      color: #d9d9dc;
    }

    &--wrap {
      display: flex;
      flex-direction: column;
      width: 350px;
      align-items: center;
      margin: 0 auto;
    }

    &:first-child {
      background-color: #f8f8fb;
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto 200px;
    }
  }

  &__forgot-pw {
    align-self: flex-end;
    margin: 8px 0 16px 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
  }
}

.btn__login {
  margin-bottom: 26px;
}

.input__login {
  margin-bottom: 30px;
}

.login-form__no-sms__text {
  font-size: 13px;
  line-height: 16px;
  color: #c6c6c6;
  margin-bottom: 16px;
  text-align: left;
}

.ankieta-page__description {
  margin-bottom: 20px;
  text-align: left;
  color: #c6c6c6;

  font-weight: 400;

  p {
    margin-bottom: 20px;
  }
}

.btn-long {
  width: 100% !important;
}

button.cmp_btn.btn-cancel {
  color: #c6c6c6;
  background-color: transparent;
  margin-right: 20px;

  &:hover {
    background-color: transparent;
    color: black;
  }
}

.btn-link {
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    color: black;
  }
}

.error-block {
  &__message {
    font-size: 12px;
    color: red;
    top: 100%;
    text-align: left;
  }
}

:deep(.input-container) {
  padding-left: 0 !important;
}
</style>
