export enum ETemplateTypes {
  agreement = 'agreement',
  task = 'task_order',
  certificate = 'acceptance_certificate'
}

export enum EEntityServerValues {
  ip = 'Индивидуальный Предприниматель',
  ooo = 'Общество с Ограниченной Ответственностью'
}

export enum EOrderStatus {
  active = 'active',
  finished = 'finished',
  cancelled = 'cancelled'
}

export enum ESuborderStatus {
  created = 'awaiting_signing',
  work = 'in_work',
  check = 'on_check',
  act = 'act_signing',
  payment = 'pending_payment',
  finished = 'finished',
  dispute = 'open_dispute',
  cancelled = 'cancelled'
}

export enum ETransactionsStatus {
  not_signed = 'act_not_signed',
  awaiting = 'awaiting_payment',
  paid = 'paid',
  cancelled = 'cancelled',
  sent = 'sent',
  error = 'error',
  rejected = 'rejected'
}

export enum EDocsStatus {
  converting_error = 'converting_error',
  exists = 'exists',
  signed = 'signed',
  not_signed = 'not_signed',
  active = 'active',
  finished = 'finished',
  terminated = 'terminated'
}

export enum EServerTransactionActionTypes {
  INCOME = 'incoming',
  OUTCOME = 'outgo',
}
