<template>
  <main-page-header :title="`Депозит ${company.title}`" class="page__title"></main-page-header>

  <div>
    <ul class="flex mb-5">
      <PriceItem :price="money.deposit" title="Депозит компании"
        tooltip="Сумма на счету компании в данный момент времени" />
      <PriceItem :price="money.reserved" title="Резерв всех заказов (в т.ч.налог + %)"
        tooltip="Сумма, на которую созданы и распределены неоплаченные заказы" />
      <PriceItem :price="money.free" title="Свободный остаток"
        tooltip="Сумма, в рамках которой возможно создать и распределить заказы" />
    </ul>
  </div>

  <div class="flex justify-between mb-5">
    <div class="flex flex-grow gap-3 mr-2">
      <UDateRange class="!max-w-[300px]" :start="filterDates.start_date" :finish="filterDates.finish_date"
        @update-start-date="(date: string) => { filterDates.start_date = date }"
        @update-finish-date="(date: string) => { filterDates.finish_date = date }" title="Фильтр по дате оплаты"
        startPlaceHolder="От" endPlaceHolder="До" />
      <UCard class="whitespace-nowrap" :value="`${formatPrice(total_paid)}&nbsp;₽`" label="Сумма оплаты самозанятым " />
      <UCard class="whitespace-nowrap" :value="`${formatPrice(total_comission)}&nbsp;₽`" label="Сумма комиссии Юнит" />
    </div>

    <div class="flex gap-3">
      <UButton label="Пополнить" size="sm" @click="showPopup = true" />
      <UButton label="Списать" size="sm" color="secondary" @click="showPopupWithdraw = true" />
    </div>
  </div>

  <div v-if="transactions.length && !loading" class="flex flex-col overflow-hidden grow">
    <UTable colspan="5" :callback="getTransactionsListOnScroll">
      <template #thead>
        <tr>
          <th>Время</th>
          <th>Обоснование</th>
          <th>Депозит до</th>
          <th>Депозит после</th>
          <th>Операция</th>
        </tr>
      </template>
      <template #tbody>
        <tr v-for="transaction in transactions" :key="transaction.id">
          <td>
            <p>{{ formatDate(transaction.created) }}</p>
            <p class="heading-gray-3">{{ formatTime(transaction.created) }}</p>
          </td>
          <td>{{ transaction.description }}</td>
          <td>{{ formatPrice(transaction.previous_balance) }}&nbsp;₽</td>
          <td>{{ formatPrice(transaction.current_balance) }}&nbsp;₽</td>
          <td>
            <p :style="{
              color: getTransactionColor(transaction.operation_type)
            }">
              {{ transaction.operation_type === EServerTransactionActionTypes.INCOME ? '+' : '-' }}
              {{ transaction.amount }}
            </p>
            <p class="heading-gray-3">
              {{ transaction.operation_type ? SERVER_TRANSACTION_ACTION_TYPES[transaction.operation_type] : '' }}
            </p>
          </td>
        </tr>
      </template>
    </UTable>
  </div>

  <DefaultPopup :show="showPopup" title="Пополнение депозита" okBtn="Пополнить" @close="clearPopupData"
    @success="fillBalance">
    <template #content>
      <div class="mb-10">
        <h3 class="mb-4">{{ company.title }}</h3>

        <p v-if="errorFillBalance" class="text-xs text-red-400" style="margin-top: -16px">{{ errorFillBalance }}</p>

        <UInput class="!max-w-none mb-4" placeholder="Введите обоснование" label="Обоснование пополнения"
          v-model="description" :value="description" />

        <UInput class="" placeholder="0,00 ₽" label="Сумма пополнения" v-model="amount" :value="amount"
          mask="valueCurrency" :showCurrency="true" />
      </div>
    </template>
  </DefaultPopup>

  <DefaultPopup :show="showPopupWithdraw" title="Списание с депозита" okBtn="Списать" @close="clearPopupData"
    @success="debitBalance">
    <template #content>
      <h3 class="mb-4">{{ company.title }}</h3>

      <p v-if="errorDebitBalance" class="text-xs text-red-400" style="margin-top: -16px">{{ errorDebitBalance }}</p>

      <UInput class="!max-w-none mb-4" placeholder="Введите обоснование" label="Обоснование списания"
        v-model="description" :value="description" />

      <UInput class="" placeholder="0,00 ₽" label="Сумма списания" v-model="amount" :value="amount" mask="valueCurrency"
        :showCurrency="true" />
    </template>
  </DefaultPopup>

  <DefaultPopup :show="showSmsPopup" title="Введите код из смс" okBtn="Продолжить" @close="clearPopupData"
    @success="checkSms">
    <template #content>
      <div class="ankieta-page__description">
        <p>Мы отправили вам временный код.</p>
        <p>Он потребуется для того, чтобы вы смогли подтвердить операцию.</p>
      </div>

      <div class="login-form">
        <UInput placeholder="Пароль из СМС" :value="sms" v-model="sms" />
        <p v-if="errorMessage" class="text-xs text-error">{{ errorMessage }}</p>
        <p class="login-form__no-sms__text">
          Не пришло СМС?
          <span v-if="timer">Отправить повторно через {{ timer }} с.</span>
          <span v-else class="btn-link" @click="getSMS">Отправить повторно смс-код</span>
        </p>
      </div>
    </template>
  </DefaultPopup>

  <Loader title="Операция обрабатывается, пожалуйста подождите" v-if="loading" />

  <SuccessModal :show="successCreated" title="Операция успешно завершена" textOk="Ок" :successAction="() => {
    successCreated = false
  }
    " />

  <ErrorModal :show="showErrorPopup" :errorAction="() => {
    showErrorPopup = false
  }
    " />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MainPageHeader from '@/components/MainPageHeader/MainPageHeader.vue'
import { formatPrice } from '@/utils/helpers'
import PriceItem from '@/components/Prices/PriceItem.vue'
import DefaultPopup from '@/components/Popups/DefaultPopup.vue'

import debounce from '@/utils/debounce'
import dayjs from 'dayjs'
import { SERVER_TRANSACTION_ACTION_TYPES, TRANSACTION_ACTION_TYPES_COLORS } from '@/utils/consts'
import { EServerTransactionActionTypes } from '@/types/api-values'
import getErrorMessage from '@/utils/errorMessage'
import Loader from '@/components/Loader/Loader.vue'
import ErrorModal from '@/components/Modals/ErrorModal.vue'
import SuccessModal from '@/components/Modals/SuccessModal.vue'
import { UTable, UDateRange, UCard, UButton, UInput } from 'unit-uikit'

const _today = new Date()
const _yesterday = new Date(new Date().setDate(_today.getDate() - 1))

export default defineComponent({
  components: {
    UTable,
    MainPageHeader,
    PriceItem,
    DefaultPopup,
    UInput,
    Loader,
    ErrorModal,
    SuccessModal,
    UDateRange,
    UCard,
    UButton
  },
  data() {
    return {
      ready: false,
      transactions: [] as any,
      company: {
        title: ''
      },
      money: {
        free: 0,
        reserved: 0,
        deposit: 0
      },
      formatPrice,
      showPopup: false,
      showPopupWithdraw: false,
      description: '',
      errorFillBalance: '',
      errorDebitBalance: '',
      errorMessage: '',
      amount: 0,
      showSmsPopup: false,
      timer: 60,
      timeout: null as any,
      sms: '',
      SERVER_TRANSACTION_ACTION_TYPES,
      EServerTransactionActionTypes,
      operationType: null as any,
      loading: false,
      showErrorPopup: false,
      successCreated: false,
      filterDates: {
        start_date: _yesterday,
        finish_date: _today
      },
      scrollData: {
        count: 0,
        requestCount: 0,
        size: 20,
        page: 1
      },
      total_paid: 0,
      total_comission: 0
    }
  },
  methods: {
    formatAmount(amount: any) {
      if (amount) {
        return (amount + '').replaceAll(',', '.').replaceAll(' ', '')
      }
      return amount
    },
    getTransactionColor(type: EServerTransactionActionTypes) {
      if (type) {
        return TRANSACTION_ACTION_TYPES_COLORS[type]
      }
    },
    formatDate(date: any) {
      return dayjs(date).format('DD.MM.YY')
    },
    formatTime(date: any) {
      return dayjs(date).format('HH:mm:ss')
    },
    startTimer() {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.timer = 60
      this.timeout = setInterval(() => {
        this.timer--
        if (this.timer <= 0) {
          clearTimeout(this.timeout)
          this.timeout = null
        }
      }, 1000)
    },
    clearPopupData() {
      this.showPopup = false
      this.description = ''
      this.amount = 0
      this.showPopupWithdraw = false
      this.errorFillBalance = ''
      this.errorDebitBalance = ''
      this.showSmsPopup = false
      this.errorMessage = ''
      this.operationType = null
      this.sms = ''
    },
    fillBalance() {
      this.startTimer()
      this.errorFillBalance = ''
      this.operationType = EServerTransactionActionTypes.INCOME
      const id = this.$route?.params?.id

      if (id && this.description && this.amount) {
        // @ts-ignore
        this.$store
          .dispatch('bills/fillBalance', {
            operation_type: this.operationType,
            amount: this.formatAmount(this.amount),
            company_id: id
          })
          .then(() => {
            this.showPopup = false
            this.showSmsPopup = true
          })
          .catch((e: any) => {
            const message = getErrorMessage(e, ['amount', 'operation_type', 'company_id'])
            this.errorFillBalance = message
          })
      }
    },
    debitBalance() {
      this.startTimer()
      this.errorDebitBalance = ''
      this.operationType = EServerTransactionActionTypes.OUTCOME
      const id = this.$route?.params?.id

      if (id && this.description && this.amount) {
        // @ts-ignore
        this.$store
          .dispatch('bills/debitBalance', {
            operation_type: this.operationType,
            amount: this.formatAmount(this.amount),
            company_id: id
          })
          .then(() => {
            this.showPopupWithdraw = false
            this.showSmsPopup = true
          })
          .catch((e: any) => {
            const message = getErrorMessage(e, ['amount', 'operation_type', 'company_id'])
            this.errorDebitBalance = message
          })
      }
    },
    checkSms() {
      const id = this.$route?.params?.id
      if (id && this.sms) {
        this.loading = true

        // @ts-ignore
        this.$store
          .dispatch('bills/createTransaction', {
            code: this.sms,
            operation_type: this.operationType,
            amount: this.formatAmount(this.amount),
            company_id: id,
            description: this.description
          })
          .then((res: any) => {
            let nonFieldErrors = ''
            const reportStr = res.NEW_REPORT
            if (reportStr) {
              const report = JSON.parse(reportStr)
              Object.keys(report).forEach((key: string) => {
                if (nonFieldErrors) {
                  return
                }
                if (report[key].status + '' === '400' && report[key].data.error) {
                  nonFieldErrors = report[key].data.error
                }
                if (report[key] && report[key].data && report[key].data.non_field_errors) {
                  nonFieldErrors = report[key].data.non_field_errors
                }
              })
            }
            this.loading = false

            if (nonFieldErrors) {
              this.errorMessage = nonFieldErrors
            } else {
              this.clearPopupData()
              this.successCreated = true
            }

            this.updateData()
          })
          .catch((e: any) => {
            this.loading = false

            const message = getErrorMessage(e, ['amount', 'operation_type', 'company_id', 'code'])
            if (message) {
              this.errorMessage = message
            } else {
              this.clearPopupData()
              this.showErrorPopup = true
            }
            this.updateData()
          })
      }
    },
    getSMS() {
      this.startTimer()
      const id = this.$route?.params?.id

      if (id && this.description && this.amount) {
        // @ts-ignore
        this.$store
          .dispatch('bills/debitBalance', {
            operation_type: this.operationType,
            amount: this.formatAmount(this.amount),
            company_id: id
          })
          .then()
          .catch((e: any) => {
            const message = getErrorMessage(e, ['amount', 'operation_type', 'company_id'])
            this.errorMessage = message
          })
      }
    },
    updateData() {
      this.getCompanyInfo()
      this.getTransactionsList()
    },
    getCompanyInfo() {
      const id = this.$route?.params?.id
      // @ts-ignore
      this.$store
        .dispatch('companies/getCompanyInfo', id)
        .then((response: any) => {
          this.money.deposit = response.company_deposit
          this.money.reserved = response.total_reserve
          this.money.free = response.company_deposit - response.total_reserve
        })
        .catch((e: any) => {
          console.error(e)
        })
    },
    getTransactionsList() {
      const id = this.$route?.params?.id

      const date_start = dayjs(this.filterDates.start_date).format('YYYY-MM-DD')
      const date_end = dayjs(this.filterDates.finish_date).format('YYYY-MM-DD')
      // @ts-ignore
      this.$store
        .dispatch('bills/getCompanyDepositList', {
          id: id,
          queryString: `?page=${this.scrollData.page}&page_size=${this.scrollData.size}` + `&date_start=${date_start}&date_end=${date_end}`
        })
        .then((response: any) => {
          const data = response
          this.scrollData.count = data.count
          this.transactions = data?.results || []
          this.total_paid = data?.total_paid || 0
          this.total_comission = data?.total_comission || 0
        })
        .catch((e: any) => {
          console.error(e)
        })
    },
    async getTransactionsListOnScroll() {
      if (this.transactions.length < this.scrollData.count) {
        this.scrollData.page++
        const id = this.$route?.params?.id

        const date_start = dayjs(this.filterDates.start_date).format('YYYY-MM-DD')
        const date_end = dayjs(this.filterDates.finish_date).format('YYYY-MM-DD')
        // @ts-ignore
        const response = await this.$store.dispatch('bills/getCompanyDepositList', {
          id: id,
          queryString: `?page=${this.scrollData.page}&page_size=${this.scrollData.size}` + `&date_start=${date_start}&date_end=${date_end}`
        })
        const data = response
        if (data.results && data.count) {
          this.scrollData.count = data.count
          this.scrollData.requestCount = data.count
          this.total_paid = data?.total_paid || 0
          this.total_comission = data?.total_comission || 0
          const result = data.results
          this.transactions.push(...result)
        }
      }
    },
    loadTransactionsWithParams() {
      this.scrollData.page = 0
      this.scrollData.count = 1
      this.transactions = []
      this.getTransactionsListOnScroll()
    }
  },
  created() {
    const id = this.$route?.params?.id

    if (id) {
      this.updateData()
      // @ts-ignore
      this.$store
        .dispatch('bills/getCompaniesListBills', '')
        .then((response: any) => {
          const company = response.results?.filter((c: any) => {
            return c.company_info?.company_id + '' === id + ''
          })
          const name = company && company[0] && company[0].company_info?.company_name
          if (name) {
            this.company.title = name
          }
        })
        .catch((e: any) => {
          console.error(e)
        })
    }
  },
  watch: {
    'filterDates.start_date'() {
      if (this.filterDates.start_date && this.filterDates.finish_date) {
        // @ts-ignore
        if (this.debounceloadTransactions) {
          // @ts-ignore
          this.debounceloadTransactions()
        } else {
          // @ts-ignore
          this.debounceloadTransactions = debounce(() => {
            this.loadTransactionsWithParams()
          })
          // @ts-ignore
          this.debounceloadTransactions()
        }
      }
    },
    'filterDates.finish_date'() {
      if (this.filterDates.start_date && this.filterDates.finish_date) {
        // @ts-ignore
        if (this.debounceloadTransactions) {
          // @ts-ignore
          this.debounceloadTransactions()
        } else {
          // @ts-ignore
          this.debounceloadTransactions = debounce(() => {
            // @ts-ignore
            this.loadEntitiesWithParams()
          })
          // @ts-ignore
          this.debounceloadTransactions()
        }
      }
    }
  }
})
</script>

<style lang="postcss" scoped>
.login-form__no-sms__text {
  font-size: 13px;
  line-height: 16px;
  color: #c6c6c6;
  margin-bottom: 16px;
  text-align: left;
}

.ankieta-page__description {
  margin-bottom: 20px;
  text-align: left;
  color: #c6c6c6;

  font-weight: 400;

  p {
    margin-bottom: 20px;
  }
}

.btn-long {
  width: 100% !important;
}

button.cmp_btn.btn-cancel {
  color: #c6c6c6;
  background-color: transparent;
  margin-right: 20px;

  &:hover {
    background-color: transparent;
    color: black;
  }
}

.btn-link {
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    color: black;
  }
}
</style>
