<template>
  <Loader v-if="loading" />
  <div class="close-edit-menu hidden" @click="closeEditMenu"></div>

  <div class="order-tasks">
    <div class="order-tasks__controls flex">
      <UISelect class="form-select mr-4" label="Статус заказа" unselectedLabel="Выберите статус" v-model="statusFilter"
        :value="statusFilter || ''" :options="suborderFilterStatuses" style="min-width: 150px" />
      <UInput searchInput placeholder="Поиск" :value="searchString" v-model="searchString" />
    </div>

    <div>
      <UTable v-if="filteredTasks.length">
        <template #thead>
          <tr>
            <th>Заказ</th>
            <th>Исполнитель</th>
            <th>Статус</th>
            <th>Объем работ</th>
            <th class="w-[120px]">
              <span class="relative">Стоимость
                <Tooltip tooltip='"На руки" c налогом, получит исполнитель на карту' />
              </span>
            </th>
            <th v-if="order?.photo_after_completion">Фото</th>
            <th class="w-[80px]" v-if="order?.status !== 'finished'"></th>
          </tr>
        </template>
        <template #tbody>
          <tr v-for="task of filteredTasks" :key="task.id" class="align-top whitespace-nowrap"
            :class="{ 'task-cancelled': task.status === ESuborderStatus.cancelled }">
            <td class="relative">
              <span class="block overflow-hidden text-ellipsis" v-tooltip>{{ getSuborderInputData(task) }}</span>
            </td>
            <td>
              <span>{{ task.worker?.worker_full_name }}</span>
              <div class="mt-1">
                <span class="status-item" :style="{
                  backgroundColor: getStatusColor(task.status)
                }">{{ getStatus(task.status) }}</span>
              </div>
            </td>
            <td class="relative">
              <UISelect label="Статус" class="form-select-small" unselectedLabel="Cтатус" :value="task.status"
                :options="suborderStatuses" @change="updateSuborderStatus($event, task)" />
            </td>
            <td class="relative">{{ task.quantity }} {{ getUnitType(task.unit_type || task.unit_types?.id) }}</td>
            <td class="relative">{{ formatPrice(getPrice(task)) }}&nbsp;₽</td>
            <td v-if="order?.photo_after_completion">
              <template v-if="task?.results">
                <img src="/icons/photo-icon.svg" class="photo-result" alt="Фото" :data-suborder="task.id" />
              </template>
            </td>
            <td class="!text-right" v-if="order?.status !== 'finished'">
              <button type="button" class="edit-btn" style="position: static" @click="openEditMenu($event, task.id)"
                :class="{ hidden: task.status === ESuborderStatus.cancelled }">
                <img class="" src="/icons/extra.svg" alt="Редактировать" />
                <div v-if="task.showEditMenu" class="edit-menu" :style="{
                  right: '40px',
                  top: editMenuTopPosition
                }"></div>
              </button>
            </td>
          </tr>
        </template>
      </UTable>
      <span class="bg-white block p-4" v-else>Нет данных</span>
    </div>
  </div>

  <ErrorModal :show="failedEdit" :errorAction="() => {
    failedEdit = false
  }
    " />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { UISelect, UInput, UTable } from 'unit-uikit'

import Tooltip from '@/components/Tooltips/Tooltips.vue'
import ErrorModal from '@/components/Modals/ErrorModal.vue'
import Loader from '@/components/Loader/Loader.vue'

import { SUBORDER_STATUS, SUBORDER_COLORS, SUBORDER_CHANGE_REASON } from '@/utils/consts'
import { ESuborderStatus } from '@/types/api-values'
import { formatPrice } from '@/utils/helpers'

function formatDate(date: string) {
  if (!date) {
    return ''
  }
  const dateArr = date?.split('-')
  if (dateArr.length === 3) {
    return dateArr.reverse().join('.')
  }
  return date
}

export default defineComponent({
  components: {
    UTable,
    UISelect,
    UInput,
    Tooltip,
    ErrorModal,
    Loader
  },
  data() {
    return {
      formatPrice,
      loading: true,
      searchString: '',
      failedEdit: false,
      statusFilter: '',
      tasks: [] as any,
      reason_for_cancellation: '',
      cancelOrderReason: '',
      subOrderId: null as any,
      order: null as any,
      reasonComment: '',
      selectedReason: [],
      backToWorkFiles: [],
      backToWorkOptions: SUBORDER_CHANGE_REASON,
      editableTask: null,
      submitTry: false,
      unitTypesList: [],
      ESuborderStatus,
      pageX: 0,
      pageY: 0,
      suborderFilterStatuses: [
        {
          id: 'status_all',
          name: 'Все'
        },
        {
          id: ESuborderStatus.created,
          name: SUBORDER_STATUS[ESuborderStatus.created]
        },
        {
          id: ESuborderStatus.work,
          name: SUBORDER_STATUS[ESuborderStatus.work]
        },
        {
          id: ESuborderStatus.check,
          name: SUBORDER_STATUS[ESuborderStatus.check]
        },
        {
          id: ESuborderStatus.act,
          name: SUBORDER_STATUS[ESuborderStatus.act]
        },
        {
          id: ESuborderStatus.payment,
          name: SUBORDER_STATUS[ESuborderStatus.payment]
        },
        {
          id: ESuborderStatus.finished,
          name: SUBORDER_STATUS[ESuborderStatus.finished]
        },
        {
          id: ESuborderStatus.dispute,
          name: SUBORDER_STATUS[ESuborderStatus.dispute]
        },
        {
          id: ESuborderStatus.cancelled,
          name: SUBORDER_STATUS[ESuborderStatus.cancelled]
        }
      ],
      suborderStatuses: [
        {
          id: ESuborderStatus.created,
          name: SUBORDER_STATUS[ESuborderStatus.created]
        },
        {
          id: ESuborderStatus.work,
          name: SUBORDER_STATUS[ESuborderStatus.work]
        },
        {
          id: ESuborderStatus.check,
          name: SUBORDER_STATUS[ESuborderStatus.check]
        },
        {
          id: ESuborderStatus.act,
          name: SUBORDER_STATUS[ESuborderStatus.act]
        },
        {
          id: ESuborderStatus.payment,
          name: SUBORDER_STATUS[ESuborderStatus.payment]
        },
        {
          id: ESuborderStatus.finished,
          name: SUBORDER_STATUS[ESuborderStatus.finished]
        },
        {
          id: ESuborderStatus.dispute,
          name: SUBORDER_STATUS[ESuborderStatus.dispute]
        },
        {
          id: ESuborderStatus.cancelled,
          name: SUBORDER_STATUS[ESuborderStatus.cancelled]
        }
      ]
    }
  },
  methods: {
    updateSuborderStatus($event: any, task: any) {
      const status = $event.target.value
      if (status && task.id) {
        // @ts-ignore
        return this.$store
          .dispatch('order/updateSuborderStatus', {
            id: task.id,
            data: {
              type: status === ESuborderStatus.cancelled ? 'cancel_suborder' : 'change_suborder',
              reason: ['another_reason'],
              new_status: status
            }
          })
          .then(() => {
            this.getTasks()

            if (this.$route.params.id) {
              // @ts-ignore
              this.$store
                .dispatch('order/getSingleOrder', this.$route.params.id)
                .then((response: any) => {
                  this.order = response
                })
                .catch((e: any) => {
                  console.error(e)
                })
            }
          })
          .catch((e: any) => {
            console.error(e)
          })
      }
    },
    getUnitType(unitType: any) {
      if (this.unitTypesList) {
        const type: any = this.unitTypesList.filter((u: any) => {
          return u.id + '' === unitType + ''
        })
        return (type && type[0] && type[0].name) || ''
      }
      return ''
    },
    getStartDate(task: any) {
      return formatDate(task.start_date || task.initial_start_date)
    },
    getFinishDate(task: any) {
      return formatDate(task.finish_date || task.initial_finish_date)
    },
    getPrice(task: any) {
      if (task.price && +task.price > 0) {
        return task.price
      }
      return task.initial_price
    },
    getQuantity(task: any) {
      if (task.quantity && +task.quantity > 0) {
        return task.quantity
      }
      return task.initial_quantity
    },
    closeEditMenu() {
      this.tasks = this.tasks.map((task: any) => {
        task.showEditMenu = false
        return task
      })
    },
    getTasks() {
      this.loading = true
      // @ts-ignore
      this.$store
        .dispatch('order/getSuborderByOrder', this.$route.params.id)
        .then((res: any) => {
          this.loading = false

          this.tasks = res.map((sub: any) => {
            if (!sub.name) {
              sub.name = sub.initial_name
            }
            if (!sub.start_date) {
              sub.start_date = formatDate(sub.initial_start_date)
            }
            if (!sub.finish_date) {
              sub.finish_date = formatDate(sub.initial_finish_date)
            }
            if (!(sub.quantity && +sub.quantity > 0)) {
              sub.quantity = sub.initial_quantity
            }
            return sub
          })
        })
        .catch((e: any) => {
          this.loading = false
          console.error(e)
        })
    },
    getStatus(status: ESuborderStatus) {
      return status && SUBORDER_STATUS[status] ? SUBORDER_STATUS[status] : ''
    },
    getStatusColor(status: ESuborderStatus) {
      return status && SUBORDER_COLORS[status] ? SUBORDER_COLORS[status] : '#27BBDC'
    },
    openEditMenu(event: MouseEvent, id: number | string) {
      this.pageX = event.pageX
      this.pageY = event.pageY
      this.tasks = this.tasks.map((task: any) => {
        if (id + '' === task.id + '') {
          task.showEditMenu = !task.showEditMenu
        } else {
          task.showEditMenu = false
        }
        return task
      })
    },
    getSuborderInputData(task: any) {
      return `№${task.id} ${task.name}`
    }
  },
  computed: {
    editMenuTopPosition() {
      return Math.min(window.innerHeight - 220, this.pageY) + 'px'
    },
    disableCancelOrder() {
      const disable =
        !(this.reason_for_cancellation && this.reason_for_cancellation !== 'Другое') &&
        !(this.reason_for_cancellation === 'Другое' && this.cancelOrderReason.length > 0)
      return disable
    },
    filteredTasks() {
      let tasks = this.tasks
      if (this.searchString) {
        tasks = tasks.filter((task: any) => {
          const worker = task.worker?.worker_full_name?.toLowerCase() || ''
          const id = (task.task_order?.id + '').toLowerCase()
          return worker.indexOf(this.searchString.toLowerCase()) !== -1 || id.indexOf(this.searchString.toLowerCase()) !== -1
        })
      }

      if (this.statusFilter && this.statusFilter !== 'status_all') {
        tasks = tasks.filter((task: any) => {
          return this.statusFilter === task.status
        })
      }

      return tasks.sort((a: any, b: any) => (a.id > b.id ? 1 : -1))
    }
  },
  created() {
    this.getTasks()

    if (this.$route.params.id) {
      // @ts-ignore
      this.$store
        .dispatch('order/getSingleOrder', this.$route.params.id)
        .then((response: any) => {
          this.order = response
        })
        .catch((e: any) => {
          console.error(e)
        })
    }
    // @ts-ignore
    this.$store
      .dispatch('order/getUnitTypes')
      .then((res: any) => {
        this.unitTypesList = res
      })
      .catch((e: any) => {
        console.error(e)
      })
  },
  watch: {
    '$route.params.id'() {
      this.getTasks()

      if (this.$route.params.id) {
        // @ts-ignore
        this.$store
          .dispatch('order/getSingleOrder', this.$route.params.id)
          .then((response: any) => {
            this.order = response
          })
          .catch((e: any) => {
            console.error(e)
          })
      }
    }
  }
})
</script>

<style lang="postcss" scoped>
td.fixed-cell img {
  vertical-align: top;
  width: 18px;
  height: auto;
}

.order-tasks {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__controls {
    margin-bottom: 45px;

    .settings-input {
      width: 44px;
      height: 100%;

      &__img {
        position: absolute;
        left: 15%;
        top: 25%;
      }
    }
  }

  &__btn-create {
    margin-left: auto;
  }

  &__cancel-popup {
    padding: 20px;
    text-align: left;
    width: 544px;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(7, 8, 54, 0.14);
    border-radius: 2px;

    .cancel-popup {
      &__close {
        position: absolute;
        right: 0;
        padding: 0;
        font-size: 28px;
        line-height: 20px;
        border-radius: 50%;
        border: none;
        transform: rotate(45deg);

        &:hover {
          cursor: pointer;
        }
      }

      &__inner {
        position: relative;
      }

      &__title {
        font-size: 18px;
        line-height: 21px;
        margin-bottom: 12px;
      }

      &__subtitle {
        font-size: 12px;
        line-height: 140%;
        color: #c6c6c6;
        margin-bottom: 20px;
      }

      &__inputs {
        display: flex;
        flex-direction: column;

        &--wrap {
          margin-bottom: 16px;
          align-items: center;

          input {
            margin-right: 15px;
          }
        }

        span:hover,
        input:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.order-popup {
  width: auto;
  max-width: 70%;
}

.other-input {
  height: 32px;
  width: 230px;
}

.btn-popup {
  width: 117px;
  height: 30px;
  margin-left: auto;
}

.task-cancelled {
  color: #e1e1e1;

  span,
  td {
    color: #e1e1e1;
  }
}

.edit-menu {
  right: 20px;
  padding: 0;

  button:disabled:hover {
    color: #dbdee5;
    cursor: default;
  }
}

.tooltip-icon {
  top: auto;
  left: auto;
  right: -20px;
  bottom: 100%;
}

.status-item {
  color: white;
  background-color: #2cb63a;
  line-height: 20px;
  border-radius: 2px;
  font-weight: 500;
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
  display: inline-block;
}

.btn-long {
  width: 100%;
}

.input-title {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 20px;
  color: #999eab;

  left: 8px;
  white-space: nowrap;

  z-index: 99;
}

.photo-result:hover {
  cursor: pointer;
}

td,
th {
  text-overflow: ellipsis;
  padding-left: 6px;
  padding-right: 6px;
}

table {
  table-layout: fixed;
}
</style>
