<template>
  <div class="warning-procuration">
    <img
      class="w-6 h-6 mr-3"
      src="/icons/error.svg"
      alt="Ошибка"
    />

    <div>
      <p class="font-medium text-base text-error mb-2">Внимание!</p>

      <p class="text-sm leading-5">
        <slot />
        <button
          type="button"
          class="upload-btn underline hover:no-underline"
        >
          Загрузите новую доверенность
        </button>
      </p>

    </div>
  </div>
</template>

<style lang="postcss" scoped>
.warning-procuration {
  box-shadow: 2px 2px 20px rgba(167, 174, 199, 0.16);
  @apply bg-white max-w-fit rounded-lg flex py-3 px-3;
}

.upload-btn {
  color: var(--primary-color);
}
</style>
