import CompaniesService from '@/services/companies.service'

export const companies = {
  namespaced: true,
  state: {},
  actions: {
    getCompanyObjects({ commit }: any, id: string) {
      return CompaniesService.getCompanyObjects(id).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    getEntityRecord({ commit }: any, id: string) {
      return CompaniesService.getEntityRecord(id).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    getCompanyProcurations({ commit }: any, { id, status }: any) {
      return CompaniesService.getCompanyProcurations(id, status).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    getCustomersList({ commit }: any, queryString: string) {
      return CompaniesService.getCustomersList(queryString).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    getCompanyObjectAgreements({ commit }: any, { id, queryString }: any) {
      return CompaniesService.getCompanyObjectAgreements(id, queryString).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    getCompaniesList({ commit }: any, status: string) {
      return CompaniesService.getCompaniesList(status).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    getWorkersInCompany({ commit }: any, { id, queryString }: any) {
      return CompaniesService.getWorkersInCompany(id, queryString).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    getOtherWorkers({ commit }: any, { id, queryString }: any) {
      return CompaniesService.getOtherWorkers(id, queryString).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    getEntitiesList({ commit }: any, queryString: string) {
      return CompaniesService.getEntitiesList(queryString).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    getObjectAgreement({ commit }: any, { companyId, objectId }: any) {
      return CompaniesService.getObjectAgreement(companyId, objectId).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    getProcuration({ commit }: any, id: string | number) {
      return CompaniesService.getProcuration(id).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    getCompanyProcurationRepresentatives({ commit }: any, id: string | number) {
      return CompaniesService.getCompanyProcurationRepresentatives(id).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    getCompanyInfo({ commit }: any, id: string | number) {
      return CompaniesService.getCompanyInfo(id).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    getCheckList({ commit }: any, queryString: string) {
      return CompaniesService.getCheckList(queryString).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    getCoworker({ commit }: any, id: string | number) {
      return CompaniesService.getCoworker(id).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    getSupportCoworker({ commit }: any, queryString: string) {
      return CompaniesService.getSupportCoworker(queryString).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    updateProcuration({ commit }: any, { id, payload }: any) {
      return CompaniesService.updateProcuration(id, payload).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    updateObjectAgreement({ commit }: any, { companyId, objectId, payload }: any) {
      return CompaniesService.updateObjectAgreement(companyId, objectId, payload).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    removeUser({ commit }: any, data: any) {
      return CompaniesService.removeUser(data).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    addUser({ commit }: any, data: any) {
      return CompaniesService.addUser(data).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    updateUNITAgreement({ commit }: any, { id, payload }: any) {
      return CompaniesService.updateUNITAgreement(id, payload).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    addWorkerToCompany({ commit }: any, data: any) {
      return CompaniesService.addWorkerToCompany(data).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    removeWorkerFromCompany({ commit }: any, data: any) {
      return CompaniesService.removeWorkerFromCompany(data).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    newProcuration({ commit }: any, data: any) {
      return CompaniesService.newProcuration(data).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    updateEntityAccountRepresentative({ commit }: any, { id, data }: any) {
      return CompaniesService.updateEntityAccountRepresentative(id, data).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    updateEntityAccountDetails({ commit }: any, { id, data }: any) {
      return CompaniesService.updateEntityAccountDetails(id, data).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    updateEntityRecord({ commit }: any, { id, data }: any) {
      return CompaniesService.updateEntityRecord(id, data).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    updateEntityAccountConditions({ commit }: any, { id, data }: any) {
      return CompaniesService.updateEntityAccountConditions(id, data).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    uploadEntityCheckList({ commit }: any, data: any) {
      return CompaniesService.uploadEntityCheckList(data).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    updateEntitySigner({ commit }: any, data: any) {
      return CompaniesService.updateEntitySigner(data).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    updateCoworker({ commit }: any, { user, formData }: any) {
      return CompaniesService.updateCoworker(user, formData).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    addCompanyCustomer({ commit }: any, data: any) {
      return CompaniesService.addCompanyCustomer(data).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    }
  }
}
