// https://github.com/hflabs/region/blob/master/region.csv

const regions = [
  {
    name: 'Москва',
    name_with_type: 'г Москва',
    federal_district: 'Центральный',
    tax_office: 7700,
    postal_code: 101000,
    iso_code: 'RU-MOW',
    timezone: 'UTC+3',
    geoname_code: 'RU.48',
    value: '48',
    geoname_id: 524894,
    geoname_name: 'Moscow'
  },
  {
    name: 'Московская',
    name_with_type: 'Московская обл',
    federal_district: 'Центральный',
    tax_office: 5000,
    postal_code: '',
    iso_code: 'RU-MOS',
    timezone: 'UTC+3',
    geoname_code: 'RU.47',
    value: '47',
    geoname_id: 524925,
    geoname_name: 'Moscow Oblast'
  },
  {
    name: 'Санкт-Петербург',
    name_with_type: 'г Санкт-Петербург',
    federal_district: 'Северо-Западный',
    tax_office: 7800,
    postal_code: 190000,
    iso_code: 'RU-SPE',
    timezone: 'UTC+3',
    geoname_code: 'RU.66',
    value: '66',
    geoname_id: 536203,
    geoname_name: 'St.-Petersburg'
  },
  {
    name: 'Адыгея',
    name_with_type: 'Респ Адыгея',
    federal_district: 'Южный',
    tax_office: '0100',
    postal_code: 385000,
    iso_code: 'RU-AD',
    timezone: 'UTC+3',
    geoname_code: 'RU.01',
    value: '01',
    geoname_id: 584222,
    geoname_name: 'Adygeya Republic'
  },
  {
    name: 'Башкортостан',
    name_with_type: 'Респ Башкортостан',
    federal_district: 'Приволжский',
    tax_office: '0200',
    postal_code: 452000,
    iso_code: 'RU-BA',
    timezone: 'UTC+5',
    geoname_code: 'RU.08',
    value: '08',
    geoname_id: 578853,
    geoname_name: 'Bashkortostan Republic'
  },
  {
    name: 'Бурятия',
    name_with_type: 'Респ Бурятия',
    federal_district: 'Дальневосточный',
    tax_office: '0300',
    postal_code: 671000,
    iso_code: 'RU-BU',
    timezone: 'UTC+8',
    geoname_code: 'RU.11',
    value: '11',
    geoname_id: 2050915,
    geoname_name: 'Buryatiya Republic'
  },
  {
    name: 'Алтай',
    name_with_type: 'Респ Алтай',
    federal_district: 'Сибирский',
    tax_office: '0400',
    postal_code: 649000,
    iso_code: 'RU-AL',
    timezone: 'UTC+7',
    geoname_code: 'RU.03',
    value: '03',
    geoname_id: 1506272,
    geoname_name: 'Altai'
  },
  {
    name: 'Дагестан',
    name_with_type: 'Респ Дагестан',
    federal_district: 'Северо-Кавказский',
    tax_office: '0500',
    postal_code: 368000,
    iso_code: 'RU-DA',
    timezone: 'UTC+3',
    geoname_code: 'RU.17',
    value: '17',
    geoname_id: 567293,
    geoname_name: 'Dagestan'
  },
  {
    name: 'Ингушетия',
    name_with_type: 'Респ Ингушетия',
    federal_district: 'Северо-Кавказский',
    tax_office: '0600',
    postal_code: 386000,
    iso_code: 'RU-IN',
    timezone: 'UTC+3',
    geoname_code: 'RU.19',
    value: '19',
    geoname_id: 556349,
    geoname_name: 'Ingushetiya Republic'
  },
  {
    name: 'Кабардино-Балкарская',
    name_with_type: 'Респ Кабардино-Балкарская',
    federal_district: 'Северо-Кавказский',
    tax_office: '0700',
    postal_code: '',
    iso_code: 'RU-KB',
    timezone: 'UTC+3',
    geoname_code: 'RU.22',
    value: '22',
    geoname_id: 554667,
    geoname_name: 'Kabardino-Balkariya Republic'
  },
  {
    name: 'Калмыкия',
    name_with_type: 'Респ Калмыкия',
    federal_district: 'Южный',
    tax_office: '0800',
    postal_code: '',
    iso_code: 'RU-KL',
    timezone: 'UTC+3',
    geoname_code: 'RU.24',
    value: '24',
    geoname_id: 553972,
    geoname_name: 'Kalmykiya Republic'
  },
  {
    name: 'Карачаево-Черкесская',
    name_with_type: 'Респ Карачаево-Черкесская',
    federal_district: 'Северо-Кавказский',
    tax_office: '0900',
    postal_code: '',
    iso_code: 'RU-KC',
    timezone: 'UTC+3',
    geoname_code: 'RU.27',
    value: '27',
    geoname_id: 552927,
    geoname_name: 'Karachayevo-Cherkesiya Republic'
  },
  {
    name: 'Карелия',
    name_with_type: 'Респ Карелия',
    federal_district: 'Северо-Западный',
    tax_office: 1000,
    postal_code: '',
    iso_code: 'RU-KR',
    timezone: 'UTC+3',
    geoname_code: 'RU.28',
    value: '28',
    geoname_id: 552548,
    geoname_name: 'Karelia'
  },
  {
    name: 'Коми',
    name_with_type: 'Респ Коми',
    federal_district: 'Северо-Западный',
    tax_office: 1100,
    postal_code: '',
    iso_code: 'RU-KO',
    timezone: 'UTC+3',
    geoname_code: 'RU.34',
    value: '34',
    geoname_id: 545854,
    geoname_name: 'Komi'
  },
  {
    name: 'Марий Эл',
    name_with_type: 'Респ Марий Эл',
    federal_district: 'Приволжский',
    tax_office: 1200,
    postal_code: '',
    iso_code: 'RU-ME',
    timezone: 'UTC+3',
    geoname_code: 'RU.45',
    value: '45',
    geoname_id: 529352,
    geoname_name: 'Mariy-El Republic'
  },
  {
    name: 'Мордовия',
    name_with_type: 'Респ Мордовия',
    federal_district: 'Приволжский',
    tax_office: 1300,
    postal_code: '',
    iso_code: 'RU-MO',
    timezone: 'UTC+3',
    geoname_code: 'RU.46',
    value: '46',
    geoname_id: 525369,
    geoname_name: 'Mordoviya Republic'
  },
  {
    name: 'Саха /Якутия/',
    name_with_type: 'Респ Саха /Якутия/',
    federal_district: 'Дальневосточный',
    tax_office: 1400,
    postal_code: '',
    iso_code: 'RU-SA',
    timezone: 'UTC+9',
    geoname_code: 'RU.63',
    value: '63',
    geoname_id: 2013162,
    geoname_name: 'Sakha'
  },
  {
    name: 'Северная Осетия - Алания',
    name_with_type: 'Респ Северная Осетия - Алания',
    federal_district: 'Северо-Кавказский',
    tax_office: 1500,
    postal_code: '',
    iso_code: 'RU-SE',
    timezone: 'UTC+3',
    geoname_code: 'RU.68',
    value: '68',
    geoname_id: 519969,
    geoname_name: 'North Ossetia'
  },
  {
    name: 'Татарстан',
    name_with_type: 'Респ Татарстан',
    federal_district: 'Приволжский',
    tax_office: 1600,
    postal_code: 420000,
    iso_code: 'RU-TA',
    timezone: 'UTC+3',
    geoname_code: 'RU.73',
    value: '73',
    geoname_id: 484048,
    geoname_name: 'Tatarstan Republic'
  },
  {
    name: 'Тыва',
    name_with_type: 'Респ Тыва',
    federal_district: 'Сибирский',
    tax_office: 1700,
    postal_code: 660000,
    iso_code: 'RU-TY',
    timezone: 'UTC+7',
    geoname_code: 'RU.79',
    value: '79',
    geoname_id: 1488873,
    geoname_name: 'Republic of Tyva'
  },
  {
    name: 'Удмуртская',
    name_with_type: 'Респ Удмуртская',
    federal_district: 'Приволжский',
    tax_office: 1800,
    postal_code: '',
    iso_code: 'RU-UD',
    timezone: 'UTC+4',
    geoname_code: 'RU.80',
    value: '80',
    geoname_id: 479613,
    geoname_name: 'Udmurtiya Republic'
  },
  {
    name: 'Хакасия',
    name_with_type: 'Респ Хакасия',
    federal_district: 'Сибирский',
    tax_office: 1900,
    postal_code: 655000,
    iso_code: 'RU-KK',
    timezone: 'UTC+7',
    geoname_code: 'RU.31',
    value: '31',
    geoname_id: 1503834,
    geoname_name: 'Khakasiya Republic'
  },
  {
    name: 'Чеченская',
    name_with_type: 'Респ Чеченская',
    federal_district: 'Северо-Кавказский',
    tax_office: 2000,
    postal_code: '',
    iso_code: 'RU-CE',
    timezone: 'UTC+3',
    geoname_code: 'RU.12',
    value: '12',
    geoname_id: 569665,
    geoname_name: 'Chechnya'
  },
  {
    name: 'Чувашская Республика',
    name_with_type: 'Чувашская Республика - Чувашия',
    federal_district: 'Приволжский',
    tax_office: 2100,
    postal_code: '',
    iso_code: 'RU-CU',
    timezone: 'UTC+3',
    geoname_code: 'RU.16',
    value: '16',
    geoname_id: 567395,
    geoname_name: 'Chuvashia'
  },
  {
    name: 'Алтайский',
    name_with_type: 'Алтайский край',
    federal_district: 'Сибирский',
    tax_office: 2200,
    postal_code: '',
    iso_code: 'RU-ALT',
    timezone: 'UTC+7',
    geoname_code: 'RU.04',
    value: '04',
    geoname_id: 1511732,
    geoname_name: 'Altai Krai'
  },
  {
    name: 'Краснодарский',
    name_with_type: 'Краснодарский край',
    federal_district: 'Южный',
    tax_office: 2300,
    postal_code: '',
    iso_code: 'RU-KDA',
    timezone: 'UTC+3',
    geoname_code: 'RU.38',
    value: '38',
    geoname_id: 542415,
    geoname_name: 'Krasnodarskiy'
  },
  {
    name: 'Красноярский',
    name_with_type: 'Красноярский край',
    federal_district: 'Сибирский',
    tax_office: 2400,
    postal_code: '',
    iso_code: 'RU-KYA',
    timezone: 'UTC+7',
    geoname_code: 'RU.91',
    value: '91',
    geoname_id: 1502020,
    geoname_name: 'Krasnoyarskiy'
  },
  {
    name: 'Приморский',
    name_with_type: 'Приморский край',
    federal_district: 'Дальневосточный',
    tax_office: 2500,
    postal_code: '',
    iso_code: 'RU-PRI',
    timezone: 'UTC+10',
    geoname_code: 'RU.59',
    value: '59',
    geoname_id: 2017623,
    geoname_name: 'Primorskiy (Maritime) Kray'
  },
  {
    name: 'Ставропольский',
    name_with_type: 'Ставропольский край',
    federal_district: 'Северо-Кавказский',
    tax_office: 2600,
    postal_code: '',
    iso_code: 'RU-STA',
    timezone: 'UTC+3',
    geoname_code: 'RU.70',
    value: '70',
    geoname_id: 487839,
    geoname_name: 'Stavropol Kray'
  },
  {
    name: 'Хабаровский',
    name_with_type: 'Хабаровский край',
    federal_district: 'Дальневосточный',
    tax_office: 2700,
    postal_code: '',
    iso_code: 'RU-KHA',
    timezone: 'UTC+10',
    geoname_code: 'RU.30',
    value: '30',
    geoname_id: 2022888,
    geoname_name: 'Khabarovsk'
  },
  {
    name: 'Амурская',
    name_with_type: 'Амурская обл',
    federal_district: 'Дальневосточный',
    tax_office: 2800,
    postal_code: 675000,
    iso_code: 'RU-AMU',
    timezone: 'UTC+9',
    geoname_code: 'RU.05',
    value: '05',
    geoname_id: 2027748,
    geoname_name: 'Amur Oblast'
  },
  {
    name: 'Архангельская',
    name_with_type: 'Архангельская обл',
    federal_district: 'Северо-Западный',
    tax_office: 2900,
    postal_code: '',
    iso_code: 'RU-ARK',
    timezone: 'UTC+3',
    geoname_code: 'RU.06',
    value: '06',
    geoname_id: 581043,
    geoname_name: 'Arkhangelskaya'
  },
  {
    name: 'Астраханская',
    name_with_type: 'Астраханская обл',
    federal_district: 'Южный',
    tax_office: 3000,
    postal_code: '',
    iso_code: 'RU-AST',
    timezone: 'UTC+4',
    geoname_code: 'RU.07',
    value: '07',
    geoname_id: 580491,
    geoname_name: 'Astrakhan'
  },
  {
    name: 'Белгородская',
    name_with_type: 'Белгородская обл',
    federal_district: 'Центральный',
    tax_office: 3100,
    postal_code: '',
    iso_code: 'RU-BEL',
    timezone: 'UTC+3',
    geoname_code: 'RU.09',
    value: '09',
    geoname_id: 578071,
    geoname_name: 'Belgorod Oblast'
  },
  {
    name: 'Брянская',
    name_with_type: 'Брянская обл',
    federal_district: 'Центральный',
    tax_office: 3200,
    postal_code: '',
    iso_code: 'RU-BRY',
    timezone: 'UTC+3',
    geoname_code: 'RU.10',
    value: '10',
    geoname_id: 571473,
    geoname_name: 'Bryansk Oblast'
  },
  {
    name: 'Владимирская',
    name_with_type: 'Владимирская обл',
    federal_district: 'Центральный',
    tax_office: 3300,
    postal_code: '',
    iso_code: 'RU-VLA',
    timezone: 'UTC+3',
    geoname_code: 'RU.83',
    value: '83',
    geoname_id: 826294,
    geoname_name: 'Vladimir'
  },
  {
    name: 'Волгоградская',
    name_with_type: 'Волгоградская обл',
    federal_district: 'Южный',
    tax_office: 3400,
    postal_code: '',
    iso_code: 'RU-VGG',
    timezone: 'UTC+3',
    geoname_code: 'RU.84',
    value: '84',
    geoname_id: 472755,
    geoname_name: 'Volgograd Oblast'
  },
  {
    name: 'Вологодская',
    name_with_type: 'Вологодская обл',
    federal_district: 'Северо-Западный',
    tax_office: 3500,
    postal_code: '',
    iso_code: 'RU-VLG',
    timezone: 'UTC+3',
    geoname_code: 'RU.85',
    value: '85',
    geoname_id: 472454,
    geoname_name: 'Vologda'
  },
  {
    name: 'Воронежская',
    name_with_type: 'Воронежская обл',
    federal_district: 'Центральный',
    tax_office: 3600,
    postal_code: '',
    iso_code: 'RU-VOR',
    timezone: 'UTC+3',
    geoname_code: 'RU.86',
    value: '86',
    geoname_id: 472039,
    geoname_name: 'Voronezj'
  },
  {
    name: 'Ивановская',
    name_with_type: 'Ивановская обл',
    federal_district: 'Центральный',
    tax_office: 3700,
    postal_code: '',
    iso_code: 'RU-IVA',
    timezone: 'UTC+3',
    geoname_code: 'RU.21',
    value: '21',
    geoname_id: 555235,
    geoname_name: 'Ivanovo'
  },
  {
    name: 'Иркутская',
    name_with_type: 'Иркутская обл',
    federal_district: 'Сибирский',
    tax_office: 3800,
    postal_code: '',
    iso_code: 'RU-IRK',
    timezone: 'UTC+8',
    geoname_code: 'RU.20',
    value: '20',
    geoname_id: 2023468,
    geoname_name: 'Irkutsk Oblast'
  },
  {
    name: 'Калининградская',
    name_with_type: 'Калининградская обл',
    federal_district: 'Северо-Западный',
    tax_office: 3900,
    postal_code: '',
    iso_code: 'RU-KGD',
    timezone: 'UTC+2',
    geoname_code: 'RU.23',
    value: '23',
    geoname_id: 554230,
    geoname_name: 'Kaliningrad'
  },
  {
    name: 'Калужская',
    name_with_type: 'Калужская обл',
    federal_district: 'Центральный',
    tax_office: 4000,
    postal_code: '',
    iso_code: 'RU-KLU',
    timezone: 'UTC+3',
    geoname_code: 'RU.25',
    value: '25',
    geoname_id: 553899,
    geoname_name: 'Kaluga'
  },
  {
    name: 'Камчатский',
    name_with_type: 'Камчатский край',
    federal_district: 'Дальневосточный',
    tax_office: 4100,
    postal_code: '',
    iso_code: 'RU-KAM',
    timezone: 'UTC+12',
    geoname_code: 'RU.92',
    value: '92',
    geoname_id: 2125072,
    geoname_name: 'Kamchatka'
  },
  {
    name: 'Кемеровская область - Кузбасс',
    name_with_type: 'Кемеровская область - Кузбасс',
    federal_district: 'Сибирский',
    tax_office: 4200,
    postal_code: '',
    iso_code: 'RU-KEM',
    timezone: 'UTC+7',
    geoname_code: 'RU.29',
    value: '29',
    geoname_id: 1503900,
    geoname_name: 'Kemerovo Oblast'
  },
  {
    name: 'Кировская',
    name_with_type: 'Кировская обл',
    federal_district: 'Приволжский',
    tax_office: 4300,
    postal_code: 610000,
    iso_code: 'RU-KIR',
    timezone: 'UTC+3',
    geoname_code: 'RU.33',
    value: '33',
    geoname_id: 548389,
    geoname_name: 'Kirov'
  },
  {
    name: 'Костромская',
    name_with_type: 'Костромская обл',
    federal_district: 'Центральный',
    tax_office: 4400,
    postal_code: '',
    iso_code: 'RU-KOS',
    timezone: 'UTC+3',
    geoname_code: 'RU.37',
    value: '37',
    geoname_id: 543871,
    geoname_name: 'Kostroma Oblast'
  },
  {
    name: 'Курганская',
    name_with_type: 'Курганская обл',
    federal_district: 'Уральский',
    tax_office: 4500,
    postal_code: 641000,
    iso_code: 'RU-KGN',
    timezone: 'UTC+5',
    geoname_code: 'RU.40',
    value: '40',
    geoname_id: 1501312,
    geoname_name: 'Kurgan Oblast'
  },
  {
    name: 'Курская',
    name_with_type: 'Курская обл',
    federal_district: 'Центральный',
    tax_office: 4600,
    postal_code: '',
    iso_code: 'RU-KRS',
    timezone: 'UTC+3',
    geoname_code: 'RU.41',
    value: '41',
    geoname_id: 538555,
    geoname_name: 'Kursk'
  },
  {
    name: 'Ленинградская',
    name_with_type: 'Ленинградская обл',
    federal_district: 'Северо-Западный',
    tax_office: 4700,
    postal_code: '',
    iso_code: 'RU-LEN',
    timezone: 'UTC+3',
    geoname_code: 'RU.42',
    value: '42',
    geoname_id: 536199,
    geoname_name: 'Leningradskaya Oblast'
  },
  {
    name: 'Липецкая',
    name_with_type: 'Липецкая обл',
    federal_district: 'Центральный',
    tax_office: 4800,
    postal_code: '',
    iso_code: 'RU-LIP',
    timezone: 'UTC+3',
    geoname_code: 'RU.43',
    value: '43',
    geoname_id: 535120,
    geoname_name: 'Lipetsk Oblast'
  },
  {
    name: 'Магаданская',
    name_with_type: 'Магаданская обл',
    federal_district: 'Дальневосточный',
    tax_office: 4900,
    postal_code: '',
    iso_code: 'RU-MAG',
    timezone: 'UTC+11',
    geoname_code: 'RU.44',
    value: '44',
    geoname_id: 2123627,
    geoname_name: 'Magadan Oblast'
  },
  {
    name: 'Мурманская',
    name_with_type: 'Мурманская обл',
    federal_district: 'Северо-Западный',
    tax_office: 5100,
    postal_code: '',
    iso_code: 'RU-MUR',
    timezone: 'UTC+3',
    geoname_code: 'RU.49',
    value: '49',
    geoname_id: 524304,
    geoname_name: 'Murmansk'
  },
  {
    name: 'Нижегородская',
    name_with_type: 'Нижегородская обл',
    federal_district: 'Приволжский',
    tax_office: 5200,
    postal_code: '',
    iso_code: 'RU-NIZ',
    timezone: 'UTC+3',
    geoname_code: 'RU.51',
    value: '51',
    geoname_id: 559838,
    geoname_name: 'Nizhny Novgorod Oblast'
  },
  {
    name: 'Новгородская',
    name_with_type: 'Новгородская обл',
    federal_district: 'Северо-Западный',
    tax_office: 5300,
    postal_code: '',
    iso_code: 'RU-NGR',
    timezone: 'UTC+3',
    geoname_code: 'RU.52',
    value: '52',
    geoname_id: 519324,
    geoname_name: 'Novgorod Oblast'
  },
  {
    name: 'Новосибирская',
    name_with_type: 'Новосибирская обл',
    federal_district: 'Сибирский',
    tax_office: 5400,
    postal_code: '',
    iso_code: 'RU-NVS',
    timezone: 'UTC+7',
    geoname_code: 'RU.53',
    value: '53',
    geoname_id: 1496745,
    geoname_name: 'Novosibirsk Oblast'
  },
  {
    name: 'Омская',
    name_with_type: 'Омская обл',
    federal_district: 'Сибирский',
    tax_office: 5500,
    postal_code: '',
    iso_code: 'RU-OMS',
    timezone: 'UTC+6',
    geoname_code: 'RU.54',
    value: '54',
    geoname_id: 1496152,
    geoname_name: 'Omsk'
  },
  {
    name: 'Оренбургская',
    name_with_type: 'Оренбургская обл',
    federal_district: 'Приволжский',
    tax_office: 5600,
    postal_code: 460000,
    iso_code: 'RU-ORE',
    timezone: 'UTC+5',
    geoname_code: 'RU.55',
    value: '55',
    geoname_id: 515001,
    geoname_name: 'Orenburg Oblast'
  },
  {
    name: 'Орловская',
    name_with_type: 'Орловская обл',
    federal_district: 'Центральный',
    tax_office: 5700,
    postal_code: '',
    iso_code: 'RU-ORL',
    timezone: 'UTC+3',
    geoname_code: 'RU.56',
    value: '56',
    geoname_id: 514801,
    geoname_name: 'Orel Oblast'
  },
  {
    name: 'Пензенская',
    name_with_type: 'Пензенская обл',
    federal_district: 'Приволжский',
    tax_office: 5800,
    postal_code: '',
    iso_code: 'RU-PNZ',
    timezone: 'UTC+3',
    geoname_code: 'RU.57',
    value: '57',
    geoname_id: 511555,
    geoname_name: 'Penza'
  },
  {
    name: 'Пермский',
    name_with_type: 'Пермский край',
    federal_district: 'Приволжский',
    tax_office: 5900,
    postal_code: '',
    iso_code: 'RU-PER',
    timezone: 'UTC+5',
    geoname_code: 'RU.90',
    value: '90',
    geoname_id: 511180,
    geoname_name: 'Perm'
  },
  {
    name: 'Псковская',
    name_with_type: 'Псковская обл',
    federal_district: 'Северо-Западный',
    tax_office: 6000,
    postal_code: '',
    iso_code: 'RU-PSK',
    timezone: 'UTC+3',
    geoname_code: 'RU.60',
    value: '60',
    geoname_id: 504338,
    geoname_name: 'Pskov Oblast'
  },
  {
    name: 'Ростовская',
    name_with_type: 'Ростовская обл',
    federal_district: 'Южный',
    tax_office: 6100,
    postal_code: '',
    iso_code: 'RU-ROS',
    timezone: 'UTC+3',
    geoname_code: 'RU.61',
    value: '61',
    geoname_id: 501165,
    geoname_name: 'Rostov'
  },
  {
    name: 'Рязанская',
    name_with_type: 'Рязанская обл',
    federal_district: 'Центральный',
    tax_office: 6200,
    postal_code: 390000,
    iso_code: 'RU-RYA',
    timezone: 'UTC+3',
    geoname_code: 'RU.62',
    value: '62',
    geoname_id: 500059,
    geoname_name: 'Ryazan Oblast'
  },
  {
    name: 'Самарская',
    name_with_type: 'Самарская обл',
    federal_district: 'Приволжский',
    tax_office: 6300,
    postal_code: '',
    iso_code: 'RU-SAM',
    timezone: 'UTC+4',
    geoname_code: 'RU.65',
    value: '65',
    geoname_id: 499068,
    geoname_name: 'Samara Oblast'
  },
  {
    name: 'Саратовская',
    name_with_type: 'Саратовская обл',
    federal_district: 'Приволжский',
    tax_office: 6400,
    postal_code: 410000,
    iso_code: 'RU-SAR',
    timezone: 'UTC+4',
    geoname_code: 'RU.67',
    value: '67',
    geoname_id: 498671,
    geoname_name: 'Saratovskaya Oblast'
  },
  {
    name: 'Сахалинская',
    name_with_type: 'Сахалинская обл',
    federal_district: 'Дальневосточный',
    tax_office: 6500,
    postal_code: 693000,
    iso_code: 'RU-SAK',
    timezone: 'UTC+11',
    geoname_code: 'RU.64',
    value: '64',
    geoname_id: 2121529,
    geoname_name: 'Sakhalin Oblast'
  },
  {
    name: 'Свердловская',
    name_with_type: 'Свердловская обл',
    federal_district: 'Уральский',
    tax_office: 6600,
    postal_code: 620000,
    iso_code: 'RU-SVE',
    timezone: 'UTC+5',
    geoname_code: 'RU.71',
    value: '71',
    geoname_id: 1490542,
    geoname_name: 'Sverdlovsk'
  },
  {
    name: 'Смоленская',
    name_with_type: 'Смоленская обл',
    federal_district: 'Центральный',
    tax_office: 6700,
    postal_code: '',
    iso_code: 'RU-SMO',
    timezone: 'UTC+3',
    geoname_code: 'RU.69',
    value: '69',
    geoname_id: 491684,
    geoname_name: 'Smolensk'
  },
  {
    name: 'Тамбовская',
    name_with_type: 'Тамбовская обл',
    federal_district: 'Центральный',
    tax_office: 6800,
    postal_code: 392000,
    iso_code: 'RU-TAM',
    timezone: 'UTC+3',
    geoname_code: 'RU.72',
    value: '72',
    geoname_id: 484638,
    geoname_name: 'Tambov'
  },
  {
    name: 'Тверская',
    name_with_type: 'Тверская обл',
    federal_district: 'Центральный',
    tax_office: 6900,
    postal_code: '',
    iso_code: 'RU-TVE',
    timezone: 'UTC+3',
    geoname_code: 'RU.77',
    value: '77',
    geoname_id: 480041,
    geoname_name: 'Tver Oblast'
  },
  {
    name: 'Томская',
    name_with_type: 'Томская обл',
    federal_district: 'Сибирский',
    tax_office: 7000,
    postal_code: '',
    iso_code: 'RU-TOM',
    timezone: 'UTC+7',
    geoname_code: 'RU.75',
    value: '75',
    geoname_id: 1489421,
    geoname_name: 'Tomsk Oblast'
  },
  {
    name: 'Тульская',
    name_with_type: 'Тульская обл',
    federal_district: 'Центральный',
    tax_office: 7100,
    postal_code: '',
    iso_code: 'RU-TUL',
    timezone: 'UTC+3',
    geoname_code: 'RU.76',
    value: '76',
    geoname_id: 480508,
    geoname_name: 'Tula'
  },
  {
    name: 'Тюменская',
    name_with_type: 'Тюменская обл',
    federal_district: 'Уральский',
    tax_office: 7200,
    postal_code: '',
    iso_code: 'RU-TYU',
    timezone: 'UTC+5',
    geoname_code: 'RU.78',
    value: '78',
    geoname_id: 1488747,
    geoname_name: 'Tyumen Oblast'
  },
  {
    name: 'Ульяновская',
    name_with_type: 'Ульяновская обл',
    federal_district: 'Приволжский',
    tax_office: 7300,
    postal_code: 433000,
    iso_code: 'RU-ULY',
    timezone: 'UTC+4',
    geoname_code: 'RU.81',
    value: '81',
    geoname_id: 479119,
    geoname_name: 'Ulyanovsk'
  },
  {
    name: 'Челябинская',
    name_with_type: 'Челябинская обл',
    federal_district: 'Уральский',
    tax_office: 7400,
    postal_code: 454000,
    iso_code: 'RU-CHE',
    timezone: 'UTC+5',
    geoname_code: 'RU.13',
    value: '13',
    geoname_id: 1508290,
    geoname_name: 'Chelyabinsk'
  },
  {
    name: 'Забайкальский',
    name_with_type: 'Забайкальский край',
    federal_district: 'Дальневосточный',
    tax_office: 7500,
    postal_code: '',
    iso_code: 'RU-ZAB',
    timezone: 'UTC+9',
    geoname_code: 'RU.93',
    value: '93',
    geoname_id: 7779061,
    geoname_name: 'Transbaikal Territory'
  },
  {
    name: 'Ярославская',
    name_with_type: 'Ярославская обл',
    federal_district: 'Центральный',
    tax_office: 7600,
    postal_code: '',
    iso_code: 'RU-YAR',
    timezone: 'UTC+3',
    geoname_code: 'RU.88',
    value: '88',
    geoname_id: 468898,
    geoname_name: 'Jaroslavl'
  },
  {
    name: 'Еврейская',
    name_with_type: 'Еврейская Аобл',
    federal_district: 'Дальневосточный',
    tax_office: 7900,
    postal_code: '',
    iso_code: 'RU-YEV',
    timezone: 'UTC+10',
    geoname_code: 'RU.89',
    value: '89',
    geoname_id: 2026639,
    geoname_name: 'Jewish Autonomous Oblast'
  },
  {
    name: 'Ненецкий',
    name_with_type: 'Ненецкий АО',
    federal_district: 'Северо-Западный',
    tax_office: 2983,
    postal_code: 166000,
    iso_code: 'RU-NEN',
    timezone: 'UTC+3',
    geoname_code: 'RU.50',
    value: '50',
    geoname_id: 522652,
    geoname_name: 'Nenets'
  },
  {
    name: 'Ханты-Мансийский Автономный округ - Югра',
    name_with_type: 'Ханты-Мансийский Автономный округ - Югра АО',
    federal_district: 'Уральский',
    tax_office: 8600,
    postal_code: '',
    iso_code: 'RU-KHM',
    timezone: 'UTC+5',
    geoname_code: 'RU.32',
    value: '32',
    geoname_id: 1503773,
    geoname_name: 'Khanty-Mansia'
  },
  {
    name: 'Чукотский',
    name_with_type: 'Чукотский АО',
    federal_district: 'Дальневосточный',
    tax_office: 8700,
    postal_code: '',
    iso_code: 'RU-CHU',
    timezone: 'UTC+12',
    geoname_code: 'RU.15',
    value: '15',
    geoname_id: 2126099,
    geoname_name: 'Chukotka'
  },
  {
    name: 'Ямало-Ненецкий',
    name_with_type: 'Ямало-Ненецкий АО',
    federal_district: 'Уральский',
    tax_office: 8900,
    postal_code: 629000,
    iso_code: 'RU-YAN',
    timezone: 'UTC+5',
    geoname_code: 'RU.87',
    value: '87',
    geoname_id: 1486462,
    geoname_name: 'Yamalo-Nenets'
  },
  {
    name: 'Крым',
    name_with_type: 'Респ Крым',
    federal_district: 'Южный',
    tax_office: 9100,
    postal_code: '',
    iso_code: 'UA-43',
    timezone: 'UTC+3',
    geoname_code: 'UA.11',
    value: '11',
    geoname_id: 703883,
    geoname_name: 'Crimea'
  },
  {
    name: 'Севастополь',
    name_with_type: 'г Севастополь',
    federal_district: 'Южный',
    tax_office: 9200,
    postal_code: 299700,
    iso_code: 'UA-40',
    timezone: 'UTC+3',
    geoname_code: 'UA.20',
    value: '20',
    geoname_id: 694422,
    geoname_name: 'Sevastopol City'
  },
  {
    name: 'Байконур',
    name_with_type: 'г Байконур',
    federal_district: '',
    tax_office: 9900,
    postal_code: 468320,
    iso_code: 'KZ-BAY',
    timezone: 'UTC+5',
    geoname_code: 'KZ.08',
    value: '08',
    geoname_id: 1538316,
    geoname_name: 'Baikonur'
  }
]

export {
  regions
}
