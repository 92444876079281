import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "worker-description" }
const _hoisted_2 = { class: "flex flex-col" }
const _hoisted_3 = { class: "mb-1" }
const _hoisted_4 = { class: "flex flex-col text-xs text-gray-400" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.getUserName(_ctx.user)), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", null, _toDisplayString(_ctx.getUserPhone(_ctx.user)), 1),
        _createElementVNode("span", null, _toDisplayString(_ctx.getUserINN(_ctx.user)), 1)
      ])
    ])
  ]))
}