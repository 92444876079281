import { SERVER_COUNTRIES } from '@/utils/consts'

export function showPassportFieldByCountry(field:string, country: string) {
  switch (field) {
    case 'start_work_notification':
    case 'end_work_notification':
      if (country === SERVER_COUNTRIES.KAZAKHSTAN || country === SERVER_COUNTRIES.ARMENIA || country === SERVER_COUNTRIES.KYRGYZSTAN) {
        return true
      }
      return false
    case 'passportType':
      if (country === SERVER_COUNTRIES.RUSSIA || country === SERVER_COUNTRIES.ARMENIA || country === SERVER_COUNTRIES.BELARUS) {
        return false
      }
      return true
    case 'subdivision_code':
      if (country === SERVER_COUNTRIES.RUSSIA) {
        return true
      }
      return false
    case 'passport_series':
      if (country === SERVER_COUNTRIES.RUSSIA) {
        return true
      }
      return false
    case 'passport_number':
      return true
    case 'passport_id_number':
      if (country === SERVER_COUNTRIES.RUSSIA || country === SERVER_COUNTRIES.ARMENIA) {
        return false
      }
      return true

    default:
      return true
  }
}

const COUNTRIES:any = {
  [SERVER_COUNTRIES.RUSSIA]: 'РФ',
  [SERVER_COUNTRIES.BELARUS]: 'Республика Беларусь',
  [SERVER_COUNTRIES.ARMENIA]: 'Армения',
  [SERVER_COUNTRIES.KAZAKHSTAN]: 'Казахстан',
  [SERVER_COUNTRIES.KYRGYZSTAN]: 'Киргизия',
  [SERVER_COUNTRIES.UKRAINE]: 'Украина',
  [SERVER_COUNTRIES.LNR]: 'ЛНР',
  [SERVER_COUNTRIES.DNR]: 'ДНР'
}

export const COUNTRI_CODES:any = {
  [SERVER_COUNTRIES.RUSSIA]: 'RU',
  [SERVER_COUNTRIES.BELARUS]: 'BY',
  [SERVER_COUNTRIES.ARMENIA]: 'AM',
  [SERVER_COUNTRIES.KAZAKHSTAN]: 'KZ',
  [SERVER_COUNTRIES.KYRGYZSTAN]: 'KG',
  [SERVER_COUNTRIES.UKRAINE]: 'UA',
  [SERVER_COUNTRIES.LNR]: 'UA',
  [SERVER_COUNTRIES.DNR]: 'UA'
}

export default COUNTRIES
