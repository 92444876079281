import { ETransactionsStatus } from '@/types/api-values'

type TError = {
  code: string,
  text: string
}

export enum EUserRoles {
  admin = 'support_admin',
  support = 'support'
}

export type TTokenPayload = {
  role: EUserRoles,
  type: string,
  user_id: number,
  exp: number,
  user_type: string
}

export type TToken = {
  access_token: string,
  refresh_token: string
}

export type TServerSuborder = {
  id: string,
  agreement: string,
  name: string,
  initial_name: string
}

export type TServerPayment = {
  id: string,
  amount: string,
  commission: string,
  suborder: TServerSuborder,
  check_image: string,
  created: Date
}

export type TServerTransation = {
  id: string,
  withholding_tax: boolean,
  bill_uuid: string,
  status: ETransactionsStatus,
  payment: TServerPayment
}

export type TBillAccount = {
  id: string,
  name: string
  bill_uuid: string,
  company: string
  created: Date,
  error: TError,
  status: string,
  workerFio: string,
  workerPhone: string
}