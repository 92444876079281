<template>
  <h2 class="text-2xl font-bold mb-5">Условия</h2>
  <div class="flex mb-5">
    <UInput class="!max-w-none flex-grow basis-1/5 mr-4" label="Лимит выплат без удержания налога"
      :value="monthly_limit_without_tax" v-model="monthly_limit_without_tax"
      @keypress.enter="updateCompanyInfo('monthly_limit_without_tax')"
      @blurAction="updateCompanyInfo('monthly_limit_without_tax')" :readonly="readonly">
      <template #icon>
        <p class="absolute top-full text-error text-xs">{{ monthly_limit_without_taxError }}</p>
      </template>
    </UInput>
    <UInput class="!max-w-none flex-grow basis-1/5 mr-4" label="Коммисия сервиса" :value="unitpay_commission"
      v-model="unitpay_commission" @keypress.enter="updateCompanyInfo('unitpay_commission')"
      @blurAction="updateCompanyInfo('unitpay_commission')" :readonly="readonly">
      <template #icon>
        <p class="absolute top-full text-error text-xs">{{ unitpay_commissionError }}</p>
      </template>
    </UInput>
    <UInput class="!max-w-none flex-grow basis-1/5 mr-4" label="Лимит выплат с удержанием налога"
      :value="monthly_limit_with_tax" v-model="monthly_limit_with_tax"
      @keypress.enter="updateCompanyInfo('monthly_limit_with_tax')"
      @blurAction="updateCompanyInfo('monthly_limit_with_tax')" :readonly="readonly">
      <template #icon>
        <p class="absolute top-full text-error text-xs">{{ monthly_limit_with_taxError }}</p>
      </template>
    </UInput>
    <UInput class="!max-w-none flex-grow basis-1/5" label="Действие рамочного договора (дней)" :value="agreement_duration"
      v-model="agreement_duration" @keypress.enter="updateCompanyMainInfo('agreement_duration')"
      @blurAction="updateCompanyMainInfo('agreement_duration')" mask="numbers" :readonly="readonly">
      <template #icon>
        <p class="absolute top-full text-error text-xs">{{ agreement_durationError }}</p>
      </template>
    </UInput>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { UInput } from 'unit-uikit'

export default defineComponent({
  components: {
    UInput
  },
  data() {
    const info = this.info as any
    return {
      monthly_limit_without_tax: info?.monthly_limit_without_tax,
      monthly_limit_without_taxError: '',
      unitpay_commission: info?.unitpay_commission,
      unitpay_commissionError: '',
      monthly_limit_with_tax: info?.monthly_limit_with_tax,
      monthly_limit_with_taxError: '',
      agreement_duration: info?.agreement_duration,
      agreement_durationError: ''
    }
  },
  props: {
    info: { type: Object },
    readonly: {
      type: Boolean,
      default: true
    }
  },
  created() { },
  methods: {
    updateCompanyMainInfo(field: string) {
      if (this.readonly) {
        return
      }

      // @ts-ignore
      this[field + 'Error'] = ''
      const id = this.$route?.params?.id
      const data = {
        // @ts-ignore

        [field]: this[field] + ' 00:00:00'
      }
      // @ts-ignore

      return this.$store
        .dispatch('companies/updateEntityRecord', { id: id, data: data })
        .then(() => {
          // @ts-ignore

          this[field + 'Error'] = ''
        })
        .catch((err: any) => {
          console.error(err)
          const data = err.response.data
          const errText = data[field]
          // @ts-ignore

          this[field + 'Error'] = errText && errText.join(', ')
        })
    },
    updateCompanyInfo(field: string) {
      // @ts-ignore
      this[field + 'Error'] = ''
      const id = this.$route?.params?.id
      const data = {
        // @ts-ignore

        [field]: this[field]
      }
      // @ts-ignore
      return this.$store
        .dispatch('companies/updateEntityAccountConditions', { id: id, data: data })
        .then(() => {
          // @ts-ignore

          this[field + 'Error'] = ''
        })
        .catch((err: any) => {
          console.error(err)
          const data = err.response.data
          const errText = data[field]
          // @ts-ignore

          this[field + 'Error'] = errText && errText.join(', ')
        })
    }
  }
})
</script>
