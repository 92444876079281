<script setup lang="ts">
import { ref } from 'vue'

import MainPageHeader from '@/components/MainPageHeader/MainPageHeader.vue'
import { UTabs } from 'unit-uikit'

const tabs = ref([
  {
    routeName: 'staff.verified',
    label: 'Проверенные'
  },
  {
    routeName: 'staff.not_verified',
    label: 'Непроверенные'
  }
])
</script>
<template>
  <main-page-header title="Сотрудники" class="page__title"></main-page-header>

  <u-tabs :tabs="tabs" />

  <router-view></router-view>
</template>
