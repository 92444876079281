import axios from 'axios'
import { API } from '@/utils/API'

class OrderService {
  getOrder(queryString: string) {
    return axios
      .get(API.GET_ORDER + `${queryString ? queryString : ''}`)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  getSingleOrder(id: string | number) {
    return axios
      .get(API.GET_SINGLE_ORDER(id))
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  getSuborderByOrder(id: string | number) {
    return axios
      .get(API.GET_SUBORDER_BY_ORDER(id))
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  getUnitTypes() {
    return axios
      .get(API.GET_UNIT_TYPES)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  getOrderDocuments(id: string | number) {
    return axios
      .get(API.GET_ORDER_DOCUMENTS(id))
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  updateSingleDoc(doc: any, data: any) {
    return axios
      .patch(API.UPDATE_SINGLE_DOC(doc), data)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  updateSingleOrder(id: any, data: any) {
    return axios
      .patch(API.PATCH_SINGLE_ORDER(id), data)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  updateSuborderStatus(id: any, data: any) {
    return axios
      .patch(API.UPDATE_SINGLE_ORDER(id), data)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
}
export default new OrderService()
