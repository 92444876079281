<template>
  <Loader v-if="loading" />
  <div class="flex flex mb-2">
    <div class="relative mr-2">
      <div class="settings-input bg-white" @click="showAllFilters = !showAllFilters">
        <img class="settings-input__img" src="/icons/settings.svg" />
      </div>
    </div>
    <div class="flex-grow mr-2 overflow-hidden">
      <div class="flex">
        <UInput class="flex-grow mr-2 input-item basis-1/4 !flex-shrink" placeholder="Номер реестра заказов"
          label="Введите номер" v-model="orderNumber" :value="orderNumber" />

        <UInput class="flex-grow mr-2 input-item basis-1/4 !flex-shrink" placeholder="Название реестра заказов"
          label="Введите Название" v-model="orderTitle" :value="orderTitle" />

        <UISelect class="form-select mr-2 basis-1/4 !flex-shrink" label="Проект" unselectedLabel="Все проекты"
          v-model="selectedProject" :value="selectedProject" :options="projectOptions" />

        <UISelect class="form-select basis-1/4 !flex-shrink" label="Объект" unselectedLabel="Все объекты"
          v-model="selectedObject" :value="selectedObject" :options="objectOptions" />
      </div>
      <div class="flex mt-2" v-if="showAllFilters">
        <UInput class="flex-grow !flex-shrink mr-2 input-item basis-1/4" placeholder="Номер задания" label="Введите номер"
          v-model="suborderNumber" :value="suborderNumber" />
        <UInput class="flex-grow !flex-shrink mr-2 input-item basis-1/4" placeholder="Компания" label="Введите  название"
          v-model="companyTitle" :value="companyTitle" />
        <UInput class="flex-grow !flex-shrink mr-2 input-item basis-1/4" placeholder="Фамилия" label="Введите Фамилию"
          v-model="searchString" :value="searchString" />
        <div class="flex-grow !flex-shrink input-item basis-1/4"></div>
      </div>
    </div>
    <div>
      <div class="flex">
        <UButton size="sm" label="Поиск" class="mr-2" @click="createFilterString" />
        <UButton size="sm" label="&#10006;" color="secondary" @click="clearFilters" />
      </div>
    </div>
  </div>

  <UTable v-if="!loading" colspan="6" :callback="loadOrders">
    <template #thead>
      <tr>
        <th class="w-[260px]">Информация о реестра заказов</th>
        <th>Компания</th>
        <th class="w-[18%]">Проект</th>
        <th class="w-[18%]">Объект</th>
        <th class="w-[120px]">Бюджет</th>
        <th class="w-[130px]">
          <span>Исполнители</span>
          <p class="heading-gray-3">Подписано/Приглашено</p>
        </th>
      </tr>
    </template>
    <template #tbody>
      <tr class="whitespace-nowrap" v-for="order in orders" :key="order.id">
        <td :class="{ 'order-cancelled': order.status === 'cancelled' }">
          <router-link :to="'/order/' + order.id">
            <span class="order-name block overflow-hidden text-ellipsis" v-tooltip>№{{ order.id }} {{ order.name }}</span>
          </router-link>
          <div class="order-description mt-1">
            <span class="status-item mr-3" :style="{
              backgroundColor: getStatusColor(order.status)
            }">{{ getStatus(order.status) }}</span>
            <span class="order-date">От {{ getFormatDate(order.created) }}</span>
            <span class="order-date">{{ getFormatDate(order.start_date) }} &dash; {{ getFormatDate(order.finish_date)
            }}</span>
          </div>
        </td>
        <td>
          <span class="block overflow-hidden text-ellipsis" v-tooltip>{{ order.company }}</span>
        </td>
        <td :class="{ 'order-cancelled': order.status === 'cancelled' }" style="word-break: break-all">
          <span class="block overflow-hidden text-ellipsis" v-tooltip>{{ getProject(order.id) }}</span>
        </td>
        <td :class="{ 'order-cancelled': order.status === 'cancelled' }" style="word-break: break-all">
          <span class="block overflow-hidden text-ellipsis" v-tooltip>{{ getObject(order.id) }}</span>
        </td>
        <td :class="{ 'order-cancelled': order.status === 'cancelled' }">
          <div class="flex">
            <Tooltip v-if="order.withholding_tax" percent class="mr-3 relative self-center"
              tooltip="Платеж проходит с удержанием налога за исполнителя" />
            <span> {{ getBudget(order.id) }}&nbsp;₽ </span>
          </div>
        </td>
        <td :class="{ 'order-cancelled': order.status === 'cancelled' }">{{ order.signed_workers }} / {{
          order.invited_workers }}</td>
      </tr>
    </template>
  </UTable>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { UInput, UButton, UISelect, UTable } from 'unit-uikit'
import { APIOrders } from '@/interfaces/main'
import { formatPrice } from '@/utils/helpers'
import Loader from '@/components/Loader/Loader.vue'
import { EOrderStatus } from '@/types/api-values'
import { ORDER_STATUS, ORDER_COLORS } from '@/utils/consts'
import Tooltip from '@/components/Tooltips/Tooltips.vue'

export default defineComponent({
  components: {
    UTable,
    UInput,
    UButton,
    UISelect,
    Loader,
    Tooltip
  },
  data() {
    return {
      loading: true,
      searchString: '',
      orders: [] as any,
      selectedProject: null as any,
      projectOptions: [] as any,
      selectedObject: null as any,
      objectOptions: [] as any,
      allObjects: [] as any,
      showAllFilters: false,
      filterString: '',
      companyTitle: '',
      suborderNumber: '',
      orderNumber: '',
      orderTitle: '',
      scrollData: {
        count: 0,
        requestCount: 0,
        size: 10,
        page: 1
      }
    }
  },
  created() {
    this.selectedProject = 'allProjects'
    this.selectedObject = 'allObjects'

    // @ts-ignore
    this.$store
      .dispatch('main/getProject')
      .then((res: any) => {
        const projects = res

        this.projectOptions = [
          {
            id: 'allProjects',
            name: 'Показать все'
          },
          ...projects
        ]
      })
      .catch((e: any) => {
        console.error(e)
      })

    // @ts-ignore
    this.$store
      .dispatch('main/getObject')
      .then((res: any) => {
        const objects = res
        this.allObjects = res

        this.objectOptions = [
          {
            id: 'allObjects',
            name: 'Показать все'
          },
          ...objects
        ]
      })
      .catch((e: any) => {
        console.error(e)
      })
    // @ts-ignore
    this.$store
      .dispatch('order/getOrder', `?page_size=${this.scrollData.size}`)
      .then((response: any) => {
        this.scrollData.count = response.count || 0
        const orders = response.results
        this.orders = orders
          ? orders.sort((a: any, b: any) => {
            return new Date(b.created).valueOf() - new Date(a.created).valueOf()
          })
          : []
        this.loading = false
      })
      .catch((e: any) => {
        this.loading = false
        console.error(e)
      })
  },
  methods: {
    loadOrdersWithParams() {
      this.scrollData.page = 0
      this.scrollData.count = 1
      this.orders = []
      this.loadOrders()
    },
    async loadOrders() {
      if (this.orders.length < this.scrollData.count) {
        this.scrollData.page++

        const queryString = `?page=${this.scrollData.page}&page_size=${this.scrollData.size}` + this.filterString
        // @ts-ignore
        const response = await this.$store.dispatch('order/getOrder', queryString)

        const data = response
        if (data.results && data.count) {
          this.scrollData.requestCount = data.count
          const orders = data.results
          const result = orders
            ? orders.sort((a: any, b: any) => {
              return new Date(b.created).valueOf() - new Date(a.created).valueOf()
            })
            : []
          this.orders.push(...result)
        }
      }
    },

    getFormatDate(date: string) {
      if (date) {
        const formatDateArr = date.slice(0, 10).replaceAll('-', '.').split('.').reverse()
        if (formatDateArr && formatDateArr[2]) {
          formatDateArr[2] = formatDateArr[2].slice(2)
        }
        return formatDateArr.join('.')
      }
    },
    getStatus(status: EOrderStatus) {
      return status && ORDER_STATUS[status] ? ORDER_STATUS[status] : ''
    },
    getStatusColor(status: EOrderStatus) {
      return status && ORDER_COLORS[status] ? ORDER_COLORS[status] : '#27BBDC'
    },
    getObject(id: number) {
      const order = this.orders.filter((o: APIOrders) => o.id === id)
      const obj = order && order[0]?.object
      const name = obj?.name
      return name || 'Нет объекта'
    },
    getProject(id: number) {
      const order = this.orders.filter((o: APIOrders) => o.id === id)
      const project = order && order[0]?.object?.project
      const name = project?.name
      return name || 'Нет проекта'
    },
    getBudget(id: number) {
      const order = this.orders.filter((o: APIOrders) => o.id === id)
      const budget = order && (order[0]?.budget_total || order[0]?.budget)
      return budget ? formatPrice(budget) : '0,00'
    },
    createFilterString() {
      let queryParams = ''
      if (this.searchString) {
        queryParams += `&last_name=${this.searchString}`
      }
      if (this.companyTitle) {
        queryParams += `&company_name=${this.companyTitle}`
      }
      if (this.suborderNumber) {
        queryParams += `&suborder_id=${this.suborderNumber}`
      }
      if (this.orderNumber) {
        queryParams += `&order_id=${this.orderNumber}`
      }
      if (this.orderTitle) {
        queryParams += `&order_suborder_name=${this.orderTitle}`
      }

      if (this.selectedProject && this.selectedProject !== 'allProjects') {
        queryParams += `&project_id=${this.selectedProject}`
      }

      if (this.selectedObject && this.selectedObject !== 'allObjects') {
        queryParams += `&object_id=${this.selectedObject}`
      }

      this.filterString = queryParams
      this.loadOrdersWithParams()
    },
    clearFilters() {
      this.companyTitle = ''
      this.suborderNumber = ''
      this.orderNumber = ''
      this.orderTitle = ''
      this.searchString = ''
      this.selectedProject = 'allProjects'
      this.selectedObject = 'allObjects'
      this.filterString = ''
      this.loadOrdersWithParams()
    }
  },
  watch: {
    selectedProject() {
      if (this.selectedProject !== 'allProjects') {
        let isObjectSelectFlag = false
        const objects = this.allObjects.filter((item: any) => {
          if (!isObjectSelectFlag) {
            isObjectSelectFlag = item.id + '' === this.selectedObject + '' && item.project?.id + '' === this.selectedProject + ''
          }
          return item.project?.id + '' === this.selectedProject + ''
        })
        this.objectOptions = [
          {
            id: 'allObjects',
            name: 'Показать все'
          },
          ...objects
        ]
        if (!isObjectSelectFlag) {
          this.selectedObject = 'allObjects'
        }
      } else {
        const objects = this.allObjects
        this.selectedObject = 'allObjects'
        this.objectOptions = [
          {
            id: 'allObjects',
            name: 'Показать все'
          },
          ...objects
        ]
      }
    },
    selectedObject() {
      if (this.selectedObject !== 'allObjects') {
        const _object = this.allObjects.filter((item: any) => {
          return item.id + '' === this.selectedObject + ''
        })
        const projectId = _object && _object[0] && _object[0].project?.id
        if (projectId && this.selectedProject + '' !== projectId + '') {
          this.selectedProject = projectId
        }
      }
    }
  }
})
</script>

<style lang="postcss" scoped>
@import '../_orders.postcss';

:deep(table) {
  @apply table-fixed;
}

td {
  word-break: break-word;
  @apply text-ellipsis text-black max-w-[404px];
}

tr:hover td {
  @apply bg-gray-100 cursor-pointer;
}

.cancelled {
  @apply text-red-600;
}

.edit-order {
  @apply p-0 align-middle;

  a {
    @apply w-full text-center inline-block;
  }

  img {
    @apply inline-block;
  }
}

.edit-order:hover {
  @apply cursor-pointer;
}

.table-body {
  @apply text-[13px];
}

.order-description {
  @apply text-[13px] whitespace-nowrap;
}

.order-cancelled {
  @apply text-[#e1e1e1];

  span {
    @apply text-[#e1e1e1];
  }
}

.order-date {
  @apply font-medium text-[10px] leading-3 text-grey ml-2;
}

.order-name {
  @apply text-black;
}

.status-item {
  @apply text-white bg-success leading-5 rounded font-medium text-xs px-2 py-1 inline-block;
}

.settings-input {
  width: 44px;
  height: 44px;

  &__img {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 24px;
  }
}
</style>
