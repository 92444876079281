/**
 * 
 * @param err error from axios request
 * @param keys array of error keys
 */

const getErrorMessage = (err: any, keys: string[] = []) => {
  let nonFieldErrors = ''
  const data = err?.response?.data

  if (keys.length) {
    keys.forEach((key: string) => {
      if (nonFieldErrors) {
        return
      }
      if (data[key]) {
        nonFieldErrors = data[key] 
      }
    })
  }
  if (!nonFieldErrors && data.non_field_errors) {
    nonFieldErrors = data.non_field_errors
  }

  if (Array.isArray(nonFieldErrors)) {
    nonFieldErrors = nonFieldErrors.join(', ')
  }

  return nonFieldErrors
}

export default getErrorMessage