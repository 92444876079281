import AuthService from '@/services/auth.service'

export const auth = {
  namespaced: true,
  state: {},
  actions: {
    refreshToken({ commit }: any) {
      return AuthService.refreshToken().then(
        jwt => {
          return Promise.resolve(jwt)
        },
        error => {
          commit('loginFailure')
          return Promise.reject(error)
        }
      )
    },
    login({ commit }: any, user: any) {
      return AuthService.login(user).then(
        user => {
          return Promise.resolve(user)
        },
        error => {
          commit('loginFailure')
          return Promise.reject(error)
        }
      )
    },
    loginConfirm({ commit }: any, user: any) {
      return AuthService.loginConfirm(user).then(
        user => {
          return Promise.resolve(user)
        },
        error => {
          commit('loginFailure')
          return Promise.reject(error)
        }
        // eslint-disable-next-line semi
      );
    },
    logout({ commit }: any) {
      AuthService.logout()
      commit('logout')
    },
    register({ commit }: any, user: any) {
      return AuthService.register(user).then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },
    registerConfirm({ commit }: any, user: any) {
      return AuthService.registerConfirm(user).then(
        (response: any) => {
          return Promise.resolve(response.data)
        },
        (error: any) => {
          return Promise.reject(error)
        }
      )
    }
  },
  mutations: {
    loginFailure() {
      AuthService.logout()
    },
    logout() {
      AuthService.logout()
    }
  }
}
