<script setup lang="ts">
import { ref } from 'vue'

import MainPageHeader from '@/components/MainPageHeader/MainPageHeader.vue'
import { UTabs } from 'unit-uikit'

const tabs = ref([
  {
    routeName: 'workers.verified',
    label: 'Верифицированные'
  },
  {
    routeName: 'workers.not_verified',
    label: 'Неверифицированные'
  }
])
</script>
<template>
  <main-page-header title="Исполнители" class="page__title"></main-page-header>
  <u-tabs :tabs="tabs" />
  <router-view></router-view>
</template>
