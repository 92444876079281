<template>
  <li class="price-item mr-4 relative">
    <p class="price-item__value">{{ formatPrice(price) }}&nbsp;₽</p>
    <p class="price-item__title">{{ title }}</p>
    <Tooltip :tooltip="tooltip" v-if="tooltip" />
  </li>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { formatPrice } from '@/utils/helpers'
import Tooltip from '@/components/Tooltips/Tooltips.vue'

export default defineComponent({
  components: {
    Tooltip
  },
  props: {
    price: {
      type: [String, Number],
      default: 0
    },
    title: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      formatPrice
    }
  },
})

</script>

<style lang="postcss" scoped>
.price-item {
  @apply max-w-[260px] h-[62px] bg-white rounded-sm py-3 px-[14px] flex flex-col items-start grow;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.01);

  &__value {
    @apply text-base font-bold leading-none mb-[6px];
  }

  &__title {
    @apply not-italic font-normal text-xs leading-none text-[#999999];
  }
}
</style>
