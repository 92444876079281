<template>
  <header class="flex items-baseline justify-between">
    <h1 class="text-3xl text-left font-medium">{{ title }}</h1>
    <div id="header-slot"></div>
    <slot></slot>
  </header>
</template>

<script lang="ts">
export default {
  name: 'main-page-header',
  props: ['title'],
  data() {
    return {
      showProfileActions: false
    }
  }
  // methods: {
  //   logout() {
  //     this.$store.dispatch('auth/logout')
  //     this.$router.push('/login')
  //   }
  // }
}
</script>

<style lang="postcss" scoped>
.header-btn {
  @apply w-10 h-10 border-none overflow-hidden text-center ml-[10px] rounded-full;

  &__image {
    @apply w-5 h-auto align-top;
  }

  &:hover {
    @apply cursor-pointer;
  }
}
</style>
