<script setup lang="ts">
import { ref } from 'vue'

import Loader from '@/components/Loader/Loader.vue'
import { useStore } from 'vuex'

import { formatPrice } from '@/utils/helpers'

import { UTable } from 'unit-uikit'

const store = useStore()
const loading = ref(false)

const ready = ref(false)
const deposits = ref([])

const scrollData = ref({
  count: 0,
  requestCount: 0,
  size: 20,
  page: 1
})

loading.value = true

store
  .dispatch('bills/getCompaniesListBills', `?page=${scrollData.value.page}&count=${scrollData.value.size}`)
  .then((response: any) => {
    scrollData.value.count = response.count
    deposits.value.push(...response.results)
    ready.value = true
    loading.value = false
  })
  .catch((e: any) => {
    console.error(e)
    ready.value = true
    loading.value = false
  })

const loadDeposites = async () => {
  if (deposits.value.length < scrollData.value.count) {
    scrollData.value.page++
    const queryString = `?page=${scrollData.value.page}&count=${scrollData.value.size}`

    const response = await store.dispatch('bills/getCompaniesListBills', queryString)

    const data = response
    if (data.results && data.count) {
      scrollData.value.requestCount = data.count
      const result = data.results
      deposits.value.push(...result)
    }
  }
}
</script>

<template>
  <Loader v-if="loading" />
  <u-table v-else colspan="4" :callback="loadDeposites">
    <template #thead>
      <tr>
        <th>Компания</th>
        <th>ИНН</th>
        <th>КПП</th>
        <th>Баланс</th>
      </tr>
    </template>
    <template #tbody>
      <tr v-for="deposit in deposits" :key="deposit.id">
        <td>
          <router-link class="block" :to="'/deposit/' + deposit.company_info?.company_id">
            <span class="order-name">{{ deposit.company_info?.company_name }} </span></router-link
          >
        </td>
        <td>{{ deposit.company_info?.company_inn }}</td>
        <td>{{ deposit.company_info?.company_kpp }}</td>
        <td>{{ formatPrice(deposit.balance) }}&nbsp;₽</td>
      </tr>
    </template>
  </u-table>
</template>

<style lang="postcss" scoped>
tr:hover td {
  @apply bg-gray-100 cursor-pointer;
}
</style>
