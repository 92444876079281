<template>
  <Loader v-if="loading" />
  <div class="flex mb-5">
    <UInput searchInput placeholder="Поиск" :value="searchString" v-model="searchString" />
  </div>
  <UTable v-if="!loading" colspan="4" :callback="loadEntities">
    <template #thead>
      <tr>
        <th class="w-[360px]">Название</th>
        <th>Ген. директор</th>
        <th class="w-[160px]">Дата регистрации</th>
        <th class="w-[200px]">Дополнительно</th>
      </tr>
    </template>
    <template #tbody>
      <tr v-for="(item, id) in entities" :key="id" @click="moveToEntity(item)">
        <td>
          {{ item.name }}
        </td>
        <td>
          <p>{{ getFullName(item.director) }}</p>
        </td>
        <td>
          <p class="date">{{ formatDate(item.created) || '' }}</p>
        </td>
        <td>
          <UAlert v-if="item.has_expiring_procuration" :label="item.has_expiring_procuration" type="error" />
        </td>
      </tr>
    </template>
  </UTable>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { formatDate } from '@/utils/helpers'
import Loader from '@/components/Loader/Loader.vue'
import debounce from '@/utils/debounce'
import { UAlert, UInput, UTable } from 'unit-uikit'

export default defineComponent({
  components: {
    Loader,
    UAlert,
    UInput,
    UTable
  },
  data() {
    return {
      scrollData: {
        count: 0,
        requestCount: 0,
        size: 20,
        page: 1
      },
      loading: true,
      entities: [] as any,
      searchString: ''
    }
  },
  methods: {
    formatDate,
    getFullName(worker: any) {
      return `${worker?.last_name || ''} ${worker?.first_name || ''} ${worker?.middle_name || ''}`
    },
    loadEntitiesWithParams() {
      this.scrollData.page = 0
      this.scrollData.count = 1
      this.entities = []
      this.loadEntities()
    },
    async loadEntities() {
      if (this.entities.length < this.scrollData.count) {
        this.scrollData.page++

        let queryString = `?page=${this.scrollData.page}&page_size=${this.scrollData.size}&verified=False`

        if (this.searchString) {
          queryString += `&name=${this.searchString}`
        }

        // @ts-ignore
        const response = await this.$store.dispatch('companies/getEntitiesList', queryString)

        const data = response
        if (data.results && data.count) {
          this.scrollData.count = data.count
          const entities = data.results
          this.entities.push(...entities)
        }
      }
    },
    loadContent() {
      this.loading = true
      // @ts-ignore

      this.$store
        .dispatch('companies/getEntitiesList', `?page=${this.scrollData.page}&page_size=${this.scrollData.size}&verified=False`)
        .then((res: any) => {
          this.scrollData.count = res.count
          this.entities = res.results
          this.loading = false
        })
        .catch((e: any) => {
          this.loading = false
          console.error(e)
        })
    },
    moveToEntity(item: any) {
      const id = item.director?.company
      if (id) {
        this.$router.push({
          name: 'companies.info',
          params: {
            id
          }
        })
      } else {
        alert('Извините, отсутствует ссылка на компанию. Обратитесь к администратору')
      }
    }
  },
  created() {
    this.loadContent()
  },
  watch: {
    searchString() {
      // @ts-ignore

      if (this.debounceloadEntities) {
        // @ts-ignore

        this.debounceloadEntities()
      } else {
        // @ts-ignore

        this.debounceloadEntities = debounce(() => {
          this.loadEntitiesWithParams()
        })
        // @ts-ignore

        this.debounceloadEntities()
      }
    }
  }
})
</script>

<style lang="postcss" scoped>
:deep(table) {
  @apply table-fixed;
}

td {
  word-break: break-word;
  @apply overflow-hidden text-ellipsis text-black max-w-[404px];
}

tr:hover td {
  @apply bg-gray-100 cursor-pointer;
}
</style>
