import BillsService from '@/services/bills.service'

export const bills = {
  namespaced: true,
  state: {},
  actions: {
    getBills({ commit }: any, queryString: string) {
      return BillsService.getBills(queryString).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    getCompanyDepositList({ commit }: any, { id, queryString }: any) {
      return BillsService.getCompanyDepositList(id, queryString).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    getCompaniesListBills({ commit }: any, queryString: string) {
      return BillsService.getCompaniesListBills(queryString).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    getBillingAccountDetails({ commit }: any) {
      return BillsService.getBillingAccountDetails().then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    getAccountFile({ commit }: any, id: string | number) {
      return BillsService.getAccountFile(id).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    updateReplenishmentAccount({ commit }: any, { id, data }: any) {
      return BillsService.updateReplenishmentAccount(id, data).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    fillBalance({ commit }: any, data: any) {
      return BillsService.fillBalance(data).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    debitBalance({ commit }: any, data: any) {
      return BillsService.debitBalance(data).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    createTransaction({ commit }: any, data: any) {
      return BillsService.createTransaction(data).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    createBillingAccount({ commit }: any, data: any) {
      return BillsService.createBillingAccount(data).then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    }
  }
}
