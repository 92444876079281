const qiwiErrCodes: any = {
  '0001': 'Не удалось провести платеж. Необходимо чтобы исполнитель подтвердил партнерство с Киви-Банк в приложении “Мой налог“',
  '0002': 'Не удалось провести платеж. Пожалуйста свяжитесь с поддержкой для уточнения деталей.',
  '0003': 'Не удалось провести платеж. Пожалуйста свяжитесь с поддержкой для уточнения деталей.',
  '0004': 'Неверный номер карты',
  '0005': 'Не удалось провести платеж. Пожалуйста свяжитесь с поддержкой для уточнения деталей.',
  '0006': 'Не удалось провести платеж. Необходимо чтобы исполнитель подтвердил партнерство с Киви-Банк в приложении “Мой налог“',
  '0007':
    'Не удалось провести платеж. Возможные причины: Годовой доход самозанятого исполнителя достиг максимального лимита. Банк эмитент карты получателя отклонил платеж. Попросите самозанятого указать другие реквизиты банковской карты, на которую возможен перевод.',
  '0008':
    'Платеж отклонен из-за лимита на удержание налога. Чтобы провести платеж, создайте заказ заново без удержания налога. Самозанятому необходимо оплатить налог самостоятельно.',
  '0009': 'Налогоплательщик не найден/не зарегистрирован',
  '0010': 'Налогоплательщик не привязан к КИВИ Банк',
  '0011': 'Неверный номер карты',
  '0012': 'Карта отклонена. Обратитесь в банк эмитента'
}

export function getQiwiErrCode(errCode: string | number): string {
  return qiwiErrCodes[errCode]
}
