<script setup lang="ts">
import { ref, Ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import dayjs from 'dayjs'
import { useRoute } from 'vue-router'

import { UInput, UTable, UAlert, UButton } from 'unit-uikit'
import Loader from '@/components/Loader/Loader.vue'

import { OBJECTS_STATUS_LABELS, TObjectAgreement, EObjectsStatus } from '@/interfaces/main'

const store = useStore()
const loading = ref(false)
const searchString = ref('')
const objects = ref([]) as Ref<TObjectAgreement[]>

defineProps({
  readonly: {
    type: Boolean,
    default: false
  }
})

const route = useRoute()
const companyID = route.params?.id as string

const openEditMenu = (id: string | number) => {
  if (id) {
    objects.value = objects.value.map((item: any) => {
      const itemId = item.id
      if (id + '' === itemId + '') {
        item.showEditMenu = !item.showEditMenu
      } else {
        item.showEditMenu = false
      }
      return item
    })
  }
}

const getAlertType = (status: EObjectsStatus) => {
  switch (status) {
    case EObjectsStatus.active:
      return 'success'
    case EObjectsStatus.archive:
      return 'error'
  }
}

const formatDate = (date: any) => {
  if (date) {
    return dayjs(date).format('DD.MM.YYYY')
  }
  return ''
}

const scrollData = {
  count: 0,
  requestCount: 0,
  size: 20,
  page: 1
}

const filteredObj = computed(() => {
  let obj = objects.value
  if (searchString.value) {
    obj = obj.filter((item: any) => {
      return item.name.includes(searchString.value) || item.object_agreements?.agreement_number.includes(searchString.value)
    })
  }
  return obj
})

const loadObjects = async () => {
  if (objects.value.length < scrollData.count) {
    scrollData.page++
    const queryString = `?page=${scrollData.page}&page_size=${scrollData.size}`
    const response = await store.dispatch('companies/getCompanyObjectAgreements', {
      id: companyID,
      queryString: queryString
    })

    const data = response
    if (data.results && data.count) {
      scrollData.requestCount = data.count
      const result = data.results
      objects.value.push(...result)
    }
  }
}

onMounted(() => {
  loading.value = true

  store
    .dispatch('companies/getCompanyObjectAgreements', {
      id: companyID,
      queryString: `?page=${scrollData.page}&page_size=${scrollData.size}`
    })
    .then((response) => {
      scrollData.count = response.count
      objects.value = response.results || []
      loading.value = false
    })
    .catch((err: any) => {
      console.error(err)
      loading.value = false
    })
})
</script>
<template>
  <Loader v-if="loading" />
  <div class="flex w-full justify-between mb-2">
    <UInput class="basis-2/4 !max-w-none" name="search" searchInput placeholder="Поиск по названию и номеру договора"
      :value="searchString" v-model="searchString" />
    <router-link :to="!filteredObj.length
        ? ''
        : {
          name: 'reportings.add',
          query: { companyId: companyID }
        }
      ">
      <UButton :disabled="!filteredObj.length" label="Сформировать отчетность" size="sm" />
    </router-link>
  </div>

  <UTable v-if="!loading" colspan="5" :callback="loadObjects">
    <template #thead>
      <tr>
        <th>Название</th>
        <th>Номер договора</th>
        <th>Дата договора</th>
        <th>Статус объекта</th>
        <th></th>
      </tr>
    </template>
    <template #tbody>
      <tr v-for="item in filteredObj" :key="item.id" class="bg-white">
        <td>
          <p class="w-max-[260px] w-[260px] break-words">{{ item.name || '-' }}</p>
        </td>
        <td>{{ item.object_agreements?.agreement_number || '-' }}</td>
        <td>{{ formatDate(item.object_agreements?.agreement_date) || '-' }}</td>
        <td>
          <UAlert :type="getAlertType(item.status)" :label="OBJECTS_STATUS_LABELS[item.status]" />
        </td>
        <td>
          <button type="button" class="edit-btn relative" @click="openEditMenu(item.id)">
            <img class="min-w-fit" src="/icons/extra.svg" alt="Редактировать" />
            <div v-if="item.showEditMenu" class="edit-menu flex flex-col">
              <router-link :to="{
                name: 'objects',
                params: {
                  id: item.id
                },
                query: { companyId: companyID }
              }">
                <button type="button" class="btn-transparent">Редактировать</button>
              </router-link>
            </div>
          </button>
        </td>
      </tr>
    </template>
  </UTable>
</template>
<style lang="postcss" scoped>
.edit-menu {
  @apply top-full right-2/4 translate-x-0 translate-y-0;

  & .btn-transparent {
    @apply min-w-max pr-[15px];
  }
}

.success-label {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: var(--success);
}
</style>
