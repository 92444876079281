function buildFormData(formData: any, data: any, parentKey?: any) {
  if (data instanceof Array) {
    Object.keys(data).forEach((key: any) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}` : key)
    })
  } else if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}.${key}` : key)
    })
  } else {
    const value = data == null ? '' : data

    formData.append(parentKey, value)
  }
}

export function jsonToFormData(data: any) {
  const formData = new FormData()
  buildFormData(formData, data)
  return formData
}
