<template>
  <router-view @click="closeEditMenu" />
</template>

<script setup lang="ts">
const closeEditMenu = ($event: any) => {
  const srcElement = $event.srcElement
  const editMenuClicked = srcElement && srcElement.closest('.edit-btn')
  const startDate = srcElement && srcElement.closest('#startDate')
  const endDate = srcElement && srcElement.closest('#endDate')
  const datePicker = srcElement && srcElement.closest('.qs-datepicker')

  if (editMenuClicked || startDate || endDate || datePicker) {
    return
  }

  const closeElement = document.querySelector('.close-edit-menu') as HTMLElement

  if (closeElement) {
    closeElement.click()
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
