import axios from 'axios'
import { API } from '@/utils/API'

class MainService {
  getUserInfo() {
    return axios
      .get(API.GET_USER_INFO)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  getObject(queryString: string) {
    return axios
      .get(API.GET_OBJECT + `${queryString ? queryString : ''}`)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  getProject() {
    return axios
      .get(API.GET_PROJECT)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  getReportingsHistory() {
    return axios
      .get(API.GET_REPORTINGS_HISTORY)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  generateReport(id: string | number, data: any) {
    return axios
      .post(API.GENERATE_REPORT(id), data)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  generateDdsUnload(data: any) {
    return axios
      .post(API.GENERATE_DDS_UNLOAD, data)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  previewTemplate(id: string | number, data: any) {
    return axios
      .post(API.PREVIEW_TEMPLATE(id), data)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
}

export default new MainService()
