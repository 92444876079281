import axios from 'axios';
import { API } from '@/utils/API'
import router from '../router'
import { useAuthStore } from '@/stores/auth'

class AuthService {
  refreshToken() {
    const authStore = useAuthStore()
    const jwt = authStore.jwt
    const refresh_token = jwt?.refresh_token
    const access_token = jwt?.access_token

    if (refresh_token) {
      return axios
        .post(API.REFRESH_TOKEN, {
          refresh: refresh_token,
          old_access: access_token
        })
        .then(response => {
          const access_token = response?.data?.access

          if (access_token) {
            authStore.saveJWT({
              access_token,
              refresh_token
            })
          }

          return response.data
        })
        .catch(error => {
          return Promise.reject(error)
        })
    }
    return Promise.reject()
  }

  login(user: any) {
    const authStore = useAuthStore()
    const phone = user?.phone?.replace(/-/g, '')

    return axios
      .post(API.AUTH_USERS, {
        phone,
        account_type: 'support'
      })
      .then(response => {
        if (response.data.access_token) {
          authStore.saveJWT(response.data)
        }

        return response.data
      });
  }

  loginConfirm(user: any) {
    const authStore = useAuthStore()
    const phone = user?.phone?.replace(/-/g, '')

    return axios
      .post(API.AUTH_USERS_OTP, {
        phone,
        account_type: 'support',
        code: user.code
      })
      .then(response => {
        if (response.data.access_token) {
          authStore.saveJWT(response.data)
        }

        return response.data;
      });
  }

  logout() {
    const authStore = useAuthStore()
    authStore.$reset()
    router.push('/login')
  }

  register(user: any) {
    const phone = user?.phone?.replace(/-/g, '')

    return axios.post(API.SIGNUP, {
      name: user.name,
      phone,
      account_type: 'support'
    }).then(response => {
      return response.data
    });
  }

  registerConfirm(user: any) {
    const phone = user?.phone?.replace(/-/g, '')

    return axios
      .post(API.SIGNUP_OTP, {
        name: user.name,
        phone,
        account_type: 'support',
        code: user.code
      })
      .then(response => {
        const authStore = useAuthStore()
        if (response.data.access_token) {
          authStore.saveJWT(response.data)
        }

        return response.data
      });
  }
}

export default new AuthService()
