<template>
  <h2 class="text-2xl font-bold mb-5">Список чек листов</h2>
  <UTable colspan="3">
    <template #thead>
      <tr>
        <th>Файл</th>
        <th>Дата загрузки</th>
        <th>Кто загрузил</th>
      </tr>
    </template>
    <template #tbody>
      <tr v-for="item in checkLists" :key="item.id">
        <td>
          <a :href="item.file" target="_blank">{{ item.name }}</a>
        </td>
        <td>{{ formatDate(item.created) }}</td>
        <td>{{ getFullName(item.initiator) }}</td>
      </tr>
    </template>
  </UTable>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { UTable } from 'unit-uikit'

import dayjs from 'dayjs'


export default defineComponent({
  components: {
    UTable
  },
  data() {
    return {
      checkLists: [] as any
    }
  },
  methods: {
    formatDate(date: any) {
      return dayjs(date).format('DD.MM.YYYY HH:mm')
    },
    getFullName(worker: any) {
      return `${worker?.username || ''} ${worker?.last_name || ''} ${worker?.first_name || ''} ${worker?.middle_name || ''}`
    }
  },
  created() {
    const id = this.$route.params.id
    if (id) {
      // @ts-ignore
      this.$store
        .dispatch('companies/getCheckList', `?company=${id}`)
        .then((res: any) => {
          this.checkLists = res
        })
        .catch((err: any) => {
          console.error(err)
        })
    }
  }
})
</script>

<style lang="postcss" scoped>
.list-table-wrap {
  @apply !overflow-visible;
}
</style>