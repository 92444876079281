<template>
  <Loader v-if="!loaded" />
  <div class="form overflow-auto" v-if="pageReady">
    <h2 class="text-2xl font-bold mb-5">Основная информация</h2>

    <div class="flex mb-5">
      <template v-if="loaded">
        <UISelect class="form-select form-select--first mr-3.5" label="Статус реестра заказов"
          unselectedLabel="Статус реестра заказов" v-model="order.status" :value="order.status || ''"
          :options="orderStatuses"
          :disabled="order.status === EOrderStatus.finished || order.status === EOrderStatus.cancelled" />
      </template>
      <div style="flex-grow: 1; flex-basis: 40%" class="mr-3.5"></div>
      <div style="flex-grow: 1; flex-basis: 40%"></div>
    </div>

    <div>
      <div class="flex mb-4 items-start">
        <div class="input-wrapper mr-3.5">
          <h3 class="input-wrapper__title">Проект</h3>
          <p class="input-wrapper__element">{{ order.object?.project?.name || '' }}</p>
        </div>

        <div class="input-wrapper mr-3.5">
          <h3 class="input-wrapper__title">Объект</h3>
          <p class="input-wrapper__element">{{ order?.object?.name || '' }}</p>
        </div>

        <div class="input-wrapper">
          <h3 class="input-wrapper__title">Период работ</h3>
          <p class="input-wrapper__element">{{ formatDateTime(order.start_date) }} &mdash; {{
            formatDateTime(order.finish_date) }}</p>
        </div>
      </div>

      <div class="flex mb-4">
        <div class="input-wrapper input-wrapper--long">
          <h3 class="input-wrapper__title">Название заказа</h3>
          <p class="input-wrapper__element">{{ order.name }}</p>
        </div>
      </div>

      <div class="flex mb-4">
        <div class="input-wrapper input-wrapper--long">
          <h3 class="input-wrapper__title">Описание услуг и работ</h3>
          <p class="input-wrapper__element">{{ order.description }}</p>
        </div>
      </div>

      <div class="flex mb-4 items-start">
        <div class="input-wrapper mr-3.5">
          <h3 class="input-wrapper__title">Адрес выполнения услуг</h3>
          <p class="input-wrapper__element">{{ order.address }}</p>
        </div>

        <div class="input-wrapper mr-3.5">
          <h3 class="input-wrapper__title">Специализированные требования к заказу</h3>
          <p class="input-wrapper__element" style="text-transform: capitalize">{{ order.special_requirements }}</p>
        </div>

        <div class="input-wrapper">
          <h3 class="input-wrapper__title">Применяемая техника, материалы, требования к допуску</h3>
          <p class="input-wrapper__element" style="text-transform: capitalize">{{ getEquipment() }}</p>
        </div>
      </div>

      <div class="flex mb-4 items-start">
        <div class="input-wrapper mr-3.5">
          <h3 class="input-wrapper__title">Объем работ</h3>
          <p class="input-wrapper__element" style="text-transform: capitalize">{{ getQuantity() }}</p>
        </div>

        <div class="input-wrapper mr-3.5">
          <h3 class="input-wrapper__title">Тип единицы</h3>
          <p class="input-wrapper__element" style="text-transform: capitalize">{{ getUnitType() }}</p>
        </div>

        <div class="input-wrapper">
          <h3 class="input-wrapper__title">Бюджет ₽</h3>
          <p class="input-wrapper__element">{{ formatPrice(order.budget_total || order.budget) }} руб</p>
        </div>
      </div>

      <div class="flex mb-4 items-start">
        <div class="input-wrapper mr-3.5">
          <h3 class="input-wrapper__title">Выбор шаблона рамочного договора</h3>
          <p class="input-wrapper__element">{{ formatTemplateName(order.agreement_template?.name) }}</p>
        </div>

        <div class="input-wrapper mr-3.5">
          <h3 class="input-wrapper__title">Выбор шаблона договора задания</h3>
          <p class="input-wrapper__element">{{ formatTemplateName(order.order_template?.name) }}</p>
        </div>

        <div class="input-wrapper">
          <h3 class="input-wrapper__title">Выбор шаблона акта</h3>
          <p class="input-wrapper__element">{{ formatTemplateName(order.acceptance_certificate_template?.name) }}</p>
        </div>
      </div>

      <div class="flex" v-if="order.omvd">
        <div class="input-wrapper input-wrapper--long">
          <h3 class="input-wrapper__title">Отделения МВД</h3>
          <p class="input-wrapper__element">{{ order.omvd }}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="overlay" v-if="showCancelPopup">
    <div class="popup-container">
      <h4 class="popup-title">Отмена заказа</h4>

      <div class="ankieta-page__description">
        <p class="mb-4">Вы уверены что хотите отменить заказ?</p>
        <p v-if="signedError" class="text-error">Вы не можете отменить заказ так как один из документов подписан</p>
      </div>

      <div class="login-form">
        <div style="display: flex">
          <UButton label="Закрыть" @click.prevent="closePopup" class="btn-cancel btn-long mr-4" />
          <UButton label="Отменить заказ" colore="secondary" @click.prevent="cancelOrder($route.params.id)" />
        </div>
      </div>
    </div>
  </div>

  <div class="overlay" v-if="cannotCancelOrder">
    <div class="popup-container">
      <h4 class="popup-title">Ошибка в отмене заказа</h4>

      <div class="ankieta-page__description">
        <p>Невозможно отменить этот заказ, так как одно или несколько заданий в этом заказе еще активно(ы)</p>
      </div>

      <div class="login-form">
        <div class="flex">
          <UButton label="Понятно" @click.prevent="closeErrorPopup" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { UISelect, UButton } from 'unit-uikit'

import Loader from '@/components/Loader/Loader.vue'

import { formatPrice } from '@/utils/helpers'
import { EOrderStatus } from '@/types/api-values'
import { ORDER_STATUS } from '@/utils/consts'

export default defineComponent({
  components: {
    UISelect,
    UButton,
    Loader
  },
  data() {
    return {
      loaded: false,
      showCancelPopup: false,
      signedError: false,
      cannotCancelOrder: false,
      order: {} as any,
      orderStatuses: [
        {
          id: EOrderStatus.active,
          name: ORDER_STATUS[EOrderStatus.active]
        },
        {
          id: EOrderStatus.cancelled,
          name: ORDER_STATUS[EOrderStatus.cancelled]
        },
        {
          id: EOrderStatus.finished,
          name: ORDER_STATUS[EOrderStatus.finished]
        }
      ],
      pageReady: false,
      EOrderStatus
    }
  },
  created() {
    // @ts-ignore
    this.$store
      .dispatch('order/getSingleOrder', this.$route.params.id)
      .then((response: any) => {
        this.order = response
        this.loaded = true
        setTimeout(() => {
          this.pageReady = true
        }, 0)
      })
      .catch((e: any) => {
        this.pageReady = true
        console.error(e)
      })
    this.showCancelPopup = false
  },
  methods: {
    getQuantity() {
      if (this.order.quantity && +this.order.quantity > 0) {
        return this.order.quantity
      }
      return this.order.initial_quantity
    },
    getUnitType() {
      const _unit = this.order.unit_types && this.order.unit_types.name
      return _unit || this.order?.unit_types?.name
    },
    getEquipment() {
      if (this.order.equipment) {
        if (Array.isArray(this.order.equipment)) {
          return this.order.equipment.length ? this.order.equipment.map((item: any) => item.name).join(', ') : 'Нет'
        }
        return this.order.equipment || 'Нет'
      }
      return 'Нет'
    },
    getSingleOrderData(id: any) {
      if (id) {
        // @ts-ignore

        this.$store
          .dispatch('order/getSingleOrder', id)
          .then((response: any) => {
            this.order = response
            this.loaded = true
          })
          .catch((e: any) => {
            console.error(e)
          })
      }
    },
    formatDateTime(date: string) {
      if (date) {
        return date.split('-').reverse().join('.')
      }
    },
    getOrderEquipment(equipment: any) {
      if (equipment.length === 0) return 'Нет'
      return equipment
    },
    formatPrice(price: number | string) {
      return formatPrice(price)
    },
    formatTemplateName(name: string) {
      if (name) {
        const chars = name.split('')
        chars[0] = chars[0].toUpperCase()
        return chars.join('').replaceAll('_', ' ')
      }
      return ''
    },
    cancelOrder(id: any) {
      // @ts-ignore

      this.$store
        .dispatch('order/getOrderDocuments', id)
        .then((response: any) => {
          const data = response
          const result = data.some((item: any) => {
            const value = [item.agreement?.status?.toUpperCase(), item.task_order?.status?.toUpperCase()].includes('SIGNED')
            return value
          })

          if (!result) {
            this.signedError = false
            // @ts-ignore

            this.$store
              .dispatch('order/updateSingleOrder', { id: id, data: { status: EOrderStatus.cancelled } })
              .then(() => {
                this.getSingleOrderData(this.$route.params.id)
                this.showCancelPopup = false
              })
              .catch((e: any) => {
                this.signedError = true
                console.error(e)
              })
          } else {
            this.signedError = true
          }
        })
        .catch(() => {
          this.cannotCancelOrder = true
        })
    },
    closePopup() {
      this.signedError = false
      this.showCancelPopup = false
      // @ts-ignore

      this.$store
        .dispatch('order/getSingleOrder', this.$route.params.id)
        .then((response: any) => {
          this.order = response
          this.loaded = true
          setTimeout(() => {
            this.pageReady = true
          }, 0)
        })
        .catch((e: any) => {
          this.pageReady = true
          console.error(e)
        })
    },
    closeErrorPopup() {
      this.showCancelPopup = false
      this.cannotCancelOrder = false
      // @ts-ignore

      this.$store
        .dispatch('order/getSingleOrder', this.$route.params.id)
        .then((response: any) => {
          this.order = response
          this.loaded = true
          setTimeout(() => {
            this.pageReady = true
          }, 0)
        })
        .catch((e: any) => {
          this.pageReady = true
          console.error(e)
        })
    }
  },
  watch: {
    'order.status'(val, prevVal) {
      if (val === EOrderStatus.active && prevVal) {
        return
      }
      if (this.pageReady) {
        if (prevVal && val === EOrderStatus.cancelled) {
          this.showCancelPopup = true
        } else if (this.$route.params.id) {
          // @ts-ignore

          this.$store
            .dispatch('order/updateSingleOrder', { id: this.$route.params.id, data: { status: val } })
            .then(() => {
              this.getSingleOrderData(this.$route.params.id)
            })
            .catch((e: any) => {
              console.error(e)
            })
        }
      }
    }
  }
})
</script>

<style lang="postcss" scoped>
.form {
  text-align: left;
  display: flex;
  flex-direction: column;
}

.form-select {
  flex-grow: 1;
  flex-basis: 40%;
  margin-left: 15px;
  max-width: none;

  &:first-of-type {
    margin-left: 0;
  }
}

.input-wrapper {
  border-color: transparent;
  overflow: auto;

  &--long {
    max-width: none;
  }
}

.date-picker {
  position: relative;
  display: flex;
  height: 44px;
  line-height: 44px;
  border: 1px solid #dbdee5;
  border-radius: 2px;
  background-color: white;
  background-image: url('/public/icons/calendar.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) 50%;
  background-size: auto 26px;

  &__title {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 20px;
    color: #999eab;

    position: absolute;
    margin-left: 8px;
  }

  &__divider {
    margin-top: 16px;
    line-height: 28px;
  }

  input {
    flex: 1;
    padding: 0 8px;
    color: #1b2742;
    cursor: pointer;
    margin-top: 16px;

    border: none;

    max-width: 80px;

    &:hover,
    &:focus,
    &:active {
      border: none;
      outline: none;
    }
  }
}

.popup-container {
  padding: 25px;
  text-align: left;
  width: 544px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(7, 8, 54, 0.14);
  border-radius: 2px;
}

.popup-title {
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 30px;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
}

.input-wrapper {
  height: auto;
}

.input-wrapper__title {
  overflow: hidden;
  width: calc(100% - 10px);
}

.btn-cancel {
  color: #c6c6c6;
  background-color: transparent;
  margin-right: 20px;
  border: 1px solid currentColor;

  &:hover {
    color: black;
  }
}
</style>
