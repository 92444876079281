<script lang="ts" setup>
const props = defineProps({
  sortState: {
    type: Boolean,
    default: null
  }
})

const emits = defineEmits(['sort'])

const sort = () => {
  if (props.sortState === null) {
    emits('sort', true)
    return
  }
  if (props.sortState === true) {
    emits('sort', false)
    return
  }
  if (props.sortState === false) {
    emits('sort', null)
  }
}

</script>

<template>
  <div class="container" @click="sort">
    <div
      class="btn-sort"
      :class="{
        hidden: sortState === null,
        '-up': sortState === true
      }"
    >
      <i
        class="icon-method text-[#4a63f1]"
      ></i>
    </div>
    <div
      class="btn-sort"
      :class="{
        '-down': sortState === false
      }"
    >
      <i
        class="icon-method"
      ></i>
    </div>
  </div>
</template>

<style lang="postcss">
.container {
  position: relative;
  width: 16px;
  cursor: pointer;
}

.btn-sort {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 16px;

  &.-up {
    z-index: 2;
    width: 8px;
    overflow: hidden;
  }

  &.-down {
    z-index: 2;
    width: 8px;
    overflow: hidden;
  }
}
</style>
