import { defineStore } from 'pinia'
import BillService from '@/services/bills.service'

function normalizeBillAccount(data: any) {
  if (data.results) {
    data.results = data.results.map((item: any) => {
      item.workerFio = `${item.worker?.last_name || ''} ${item.worker?.first_name || ''} ${item.worker?.middle_name || ''}`
      item.workerPhone = item.worker?.phone
      return item
    })
  }
  return data
}

export const useFinancesStore = defineStore('finances', () => {
  async function fetchListAccounts(queryString = '') {
    try {
      const data = await BillService.getListAccounts(queryString)
      return data ? normalizeBillAccount(data) : {}
    } catch (error) {
      return error
    }
  }

  function $reset() {
    //
  }

  return {
    fetchListAccounts,
    $reset
  }
})
