import axios from 'axios'
import { API } from '@/utils/API'

class BillsService {
  getListAccounts(queryString: string) {
    return axios
      .get(API.GET_LIST_ACCOUNTS + queryString)
      .then((result) => {
        return result.data
      })
      .catch((error) => {
        throw error
      })
  }

  getBills(queryString: string) {
    return axios
      .get(API.GET_BILLS + queryString)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }

  getCompanyDepositList(id: string | number, queryString: string) {
    return axios
      .get(API.GET_COMPANY_DEPOSIT_LIST(id) + queryString)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }

  getCompaniesListBills(queryString: string) {
    return axios
      .get(API.GET_COMPANIES_LIST_BILLS + `${queryString || ''}`)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }

  getBillingAccountDetails() {
    return axios
      .get(API.GET_BILLING_ACCOUNT_DETAILS)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }

  getAccountFile(id: string | number) {
    return axios
      .get(API.GET_ACCOUNT_FILE(id))
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }

  updateReplenishmentAccount(id: any, data: any) {
    return axios
      .patch(API.REPLENISHMENT_ACCOUNT(id), data)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }

  fillBalance(data: any) {
    return axios
      .post(API.FILL_BALANCE, data)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }

  debitBalance(data: any) {
    return axios
      .post(API.DEBIT_BALANCE, data)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }

  createTransaction(data: any) {
    return axios
      .post(API.CREATE_TRANSACTION, data)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }

  createBillingAccount(data: any) {
    return axios
      .post(API.CREATE_BILLING_ACCOUNT, data)
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
}

export default new BillsService()
